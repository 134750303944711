<template>
  <section class="ultra-box gb-section" :class="theme">
    <div class="ultra-box-wrapper gb-wrapper">
      <div class="ultra-box-bullet one" v-if="!theme.includes('-white')"></div>
      <div class="ultra-box-bullet two" v-if="!theme.includes('-white')"></div>
      <div class="ultra-box-bullet three" v-if="!theme.includes('-white')"></div>
      <div class="ultra-box-bullet four" v-if="!theme.includes('-white')"></div>
      <div class="ultra-box-bullet five" v-if="!theme.includes('-white')"></div>
      <div class="ultra-box-bullet six" v-if="!theme.includes('-white')"></div>
      <slot v-if="!$slots['sidebar']"></slot>
      <div class="gb-wrapper fd-row" v-else>
        <UltraColText columns="three-quarter">
          <slot></slot>
        </UltraColText>
        <UltraColText columns="one-quarter">
          <slot name="sidebar"></slot>
        </UltraColText>
      </div>
    </div>
  </section>
</template>

<script>
import UltraColText from "@/components/UltraColText";
export default {
  name: "UltraBox",
  components: {UltraColText},
  props: {
    theme: {
      type: String,
      default: 'default',
    },
  },
}
</script>

<style scoped>
.ultra-box {
  padding: 100px 0;
  background-color: var(--color-white);
  position: relative;
}
.ultra-box:before {
  content: '';
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
}
.ultra-box:after {
  content: '';
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  mix-blend-mode: multiply;
}
.ultra-box[class^="default"]:before,
.ultra-box[class*=" default"]:before {
  display: block;
  background-color: var(--color-white);
}
.ultra-box[class^="secondary"]:before,
.ultra-box[class*=" secondary"]:before {
  display: block;
  background-color: var(--color-secondary-dark);
}
.ultra-box[class^="primary"]:before,
.ultra-box[class*=" primary"]:before {
  display: block;
  background-color: var(--color-primary-dark);
}
.ultra-box[class^="gray"]:before,
.ultra-box[class*=" gray"]:before {
  display: block;
  background-color: #adadad;
}
.ultra-box.default:after,
.ultra-box.gray:after,
.ultra-box.secondary:after,
.ultra-box.primary:after {
  display: block;
  background: url("@/assets/images/background-bullets-min.svg") repeat-y left top;
  background-size: 100% auto;
  mix-blend-mode: color-burn;
  opacity: .1;
}
.ultra-box[class*="-tiny"]:after {
  display: block;
  background: url("@/assets/images/background-tiny.svg") repeat-y right top;
  background-size: 100% auto;
  mix-blend-mode: color-burn;
  opacity: .1;
}
.ultra-box[class*="-arrow"]:after {
  display: block;
  background: url("@/assets/images/background-gray.svg") repeat-y right top;
  background-size: 70% auto;
  mix-blend-mode: color-burn;
}
.ultra-box[class*="-white"]:after {
  display: block;
  background: unset;
  background-size: unset;
  mix-blend-mode: unset;
  opacity: unset;
}
.ultra-box-wrapper {
  flex-direction: row;
  flex-wrap: wrap;
  z-index: 1;
  position: relative;
}

@keyframes accidentalMove {
  0% { transform: translateX(0) }
  50% { transform: translateX(20px) }
  0% { transform: translateX(0) }
}
@keyframes accidentalMove2 {
  0% { transform: translateX(0) }
  50% { transform: translateX(-20px) }
  0% { transform: translateX(0) }
}

.ultra-box-bullet {
  position: absolute;
  display: block;
  width: 30px;
  height: 30px;
  top: 36px;
  left: calc(50% - 60px);
  border-radius: 50%;
  background-color: var(--color-primary);
  animation: accidentalMove 8s ease-in-out infinite;
}
[class^="secondary"] .ultra-box-bullet,
[class*=" secondary"] .ultra-box-bullet {
  background-color: var(--color-primary);
}
[class^="primary"] .ultra-box-bullet,
[class*=" primary"] .ultra-box-bullet {
  background-color: var(--color-secondary);
}
[class^="gray"] .ultra-box-bullet,
[class*=" gray"] .ultra-box-bullet {
  background-color: var(--color-primary);
}
.ultra-box-bullet.two {
  width: 40px;
  height: 40px;
  top: -80px;
  left: calc(50% - -168px);
  animation: accidentalMove2 10s ease-in-out infinite;
}
.ultra-box-bullet.three,
.ultra-box-bullet.four,
.ultra-box-bullet.five,
.ultra-box-bullet.six {
  display: none;
}

[class*="-tiny"] .ultra-box-bullet {
  position: absolute;
  display: block;
  width: 6px;
  height: 6px;
  top: -80px;
  left: calc(50% - 200px);
  clip-path: unset;
  border-radius: 50%;
  animation: accidentalMove 8s ease-in-out infinite;
}
[class*="-tiny"] .ultra-box-bullet.two {
  width: 14px;
  height: 14px;
  top: -60px;
  left: calc(50% - 50px);
  animation: accidentalMove2 10s ease-in-out infinite;
}
[class*="-tiny"] .ultra-box-bullet.three {
  width: 6px;
  height: 6px;
  top: -30px;
  left: calc(50% - 300px);
  animation: accidentalMove2 12s ease-in-out infinite;
}
[class*="-tiny"] .ultra-box-bullet.four {
  width: 8px;
  height: 8px;
  top: -50px;
  left: calc(50% + 200px);
  animation: accidentalMove2 12s ease-in-out infinite;
}
[class*="-tiny"] .ultra-box-bullet.five {
  width: 14px;
  height: 14px;
  top: -20px;
  left: calc(50% + 400px);
  animation: accidentalMove1 10s ease-in-out infinite;
}
[class*="-tiny"] .ultra-box-bullet.six {
  width: 6px;
  height: 6px;
  top: 30px;
  left: calc(50% - 70px);
  animation: accidentalMove2 12s ease-in-out infinite;
}
@media only screen and (max-width: 600px) {
  .ultra-box-bullet.one,
  .ultra-box-bullet.two,
  .ultra-box-bullet.three,
  .ultra-box-bullet.four,
  .ultra-box-bullet.five,
  .ultra-box-bullet.six {
    display: none;
  }
}
</style>