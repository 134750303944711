<template>
  <div class="block-posts" v-if="posts && !Array.isArray(posts)">
    <UltraBox :theme="posts.details ? posts.details.theme : 'default'">
      <header class="block-posts-header" data-aos="zoom-in-up">
        <div class="block-posts-header-content">
          <h2 class="fn-color mb-10"
              :class="posts.details.titleColor ?? 'primary'"
              v-if="posts.title">{{ posts.title }}</h2>
          <p class="w-75 fn-color" v-if="posts.description"
             :class="posts.details.textColor ?? 'black'">{{ posts.description }}</p>
        </div>
        <div class="block-posts-header-action fn-color" :class="posts.details.actionColor ?? 'white'">
          <UltraAction v-if="posts.action" :action="posts.action" />
        </div>
      </header>
      <section class="block-posts-section gb-wrapper" v-if="!posts.details || !posts.details.modifier || posts.details.modifier !== 'list'">
        <Carousel :itemsToShow="4" :autoplay="7000">
          <Slide v-for="item in posts.items" :key="item.id">
            <UltraCardPost :post="item" :modifier="posts.details.modifier" />
          </Slide>
          <template #addons>
            <Pagination />
            <Navigation />
          </template>
        </Carousel>
      </section>
      <section class="block-posts-section" v-else>
        <UltraCardPost :post="item" v-for="item in posts.items.slice(0,2)" :key="item.id" />
        <UltraCardPost :post="item" v-for="item in posts.items.slice(2)" :key="item.id" modifier="list" />
      </section>
    </UltraBox>
  </div>
  <div class="block-posts" v-else-if="posts && Array.isArray(posts)">
    <UltraBox v-for="(postItem, index) in posts"
              :theme="postItem.details ? postItem.details.theme : 'default'"
              :key="postItem.id ?? index">
      <header class="block-posts-header" data-aos="zoom-in-up">
        <div class="block-posts-header-content">
          <h2 class="fn-color mb-10"
              :class="postItem.details.titleColor ?? 'primary'"
              v-if="postItem.title">{{ postItem.title }}</h2>
          <p class="fn-color" v-if="postItem.description"
             :class="postItem.details.textColor ?? 'black'">{{ postItem.description }}</p>
        </div>
        <div class="block-posts-header-action fn-color" :class="postItem.details.actionColor ?? 'white'">
          <UltraAction v-if="postItem.action" :action="postItem.action" />
        </div>
      </header>
      <section class="block-posts-section" v-if="!postItem.details || !postItem.details.modifier || postItem.details.modifier !== 'list'">
        <Carousel :settings="settings" :breakpoints="breakpoints">
          <Slide v-for="item in postItem.items" :key="item.id">
            <UltraCardPost :post="item" />
          </Slide>
          <template #addons>
            <Pagination />
            <Navigation />
          </template>
        </Carousel>
      </section>
      <section class="block-posts-section" v-else>
        <UltraCardPost :post="item" v-for="item in postItem.items.slice(0,2)" :key="item.id" />
        <UltraCardPost :post="item" v-for="item in postItem.items.slice(2)" :key="item.id" modifier="list" />
      </section>
    </UltraBox>
  </div>
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import UltraBox from "@/components/UltraBox";
import UltraAction from "@/components/UltraAction";
import UltraCardPost from "@/components/UltraCardPost";

export default {
  name: "BlockPosts",
  data() {
    return {
      settings: {
          itemsToShow: 1,
          snapAlign: 'center',
      },
      breakpoints: {
        600: {
          itemsToShow: 4,
          snapAlign: 'center',
        },
      },
    };
  },
  components: {
    UltraCardPost,
    UltraAction,
    UltraBox,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  computed: {
    posts() {
      return this.$store.getters['posts'];
    }
  },
}
</script>

<style scoped>
.block-posts {
  text-align: center;
  width: 100%;
  height: auto;
  position: relative;
  display: inline-block;
}
.block-posts-header {
  width: 100%;
  margin-bottom: 40px;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
}
.block-posts-header-content {
  display: inline-flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: flex-start;
}
.block-posts-header-action {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}
.block-posts-section {
  width: 100%;
  position: relative;
}


@media only screen and (max-width: 600px) {
  .block-posts-section {
    padding: 0 10px;
  }
  .block-posts-header {
    flex-wrap: wrap;
  }
}
</style>