const publicPath = process.env.BASE_URL;
const result = async () => {
    const content = await loadPageContainer();
    if (content) return content;
    const result = await fetch(`${publicPath}config/settings.json`);
    if (result.status !== 200) return null;
    return await result.json();
};

const loadPageContainer = async () => {
    const { pageContainer } = window;
    if (!pageContainer) return null;
    const container = JSON.parse(pageContainer);
    if (!container) return null;
    return container;
};

export default result;
