<template>
  <div class="ultra-action" v-if="Array.isArray(action)">
    <UltraBtn v-for="item in action"
              :action="item"
              :size="item.size ?? size"
              :color="item.color ?? color"
              :key="item.id">
      <slot></slot>
    </UltraBtn>
  </div>
  <UltraBtn :action="action" :size="action.size ?? size" :color="action.color ?? color" v-else>
    <slot></slot>
  </UltraBtn>
</template>

<script>
import mixins from "@/mixins";
import UltraBtn from "@/components/UltraBtn";

export default {
  name: "UltraAction",
  components: {UltraBtn},
  mixins: [mixins],
  props: {
    action: {
      type: [Object, Array],
      required: false,
    },
    size: {
      type: String,
      default: 'normal',
    },
    color: {
      type: String,
      default: 'secondary',
    },
  },
}
</script>

<style scoped>
  .ultra-action {
    display: inline;
  }
  .ultra-action > * {
    margin: 5px;
  }
</style>