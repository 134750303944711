<template>
  <div class="ultra-card" v-if="card">
    <header class="ultra-card-header" data-aos="zoom-in-up">
      <i v-if="card.icon" :class="`icon-${card.icon} ultra-large`"></i>
    </header>
    <section class="ultra-card-section" data-aos="zoom-in-down">
      <h2 class="fn-color secondary tx-uppercase mb-10" v-if="card.title">{{ card.title }}</h2>
      <p v-if="card.description">{{ card.description }}</p>
    </section>
  </div>
</template>

<script>
export default {
  name: "UltraCard",
  props: {
    card: {
      type: Object,
      required: true,
    },
  }
}
</script>

<style scoped>
.ultra-card {
  text-align: center;
}
.ultra-card-header {
  color: var(--color-white);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 200px;
  width: 100%;
  margin-bottom: 20px;
}
.ultra-card-header:before {
  content: '';
  position: absolute;
  width: 120px;
  height: 120px;
  background-color: var(--color-primary-dark);
  border-radius: 30px;
  transform: rotate(-18deg);
  margin-left: 30px;
  margin-top: -10px;
}
.ultra-card-header:after {
  content: '';
  position: absolute;
  width: 80px;
  height: 120px;
  background-color: var(--color-secondary);
  border-radius: 30px;
  transform: rotate(-18deg);
  margin-right: 60px;
  margin-top: 50px;
}
[class^="col-"]:nth-child(2n) .ultra-card-header:before,
[class*=" col-"]:nth-child(2n) .ultra-card-header:before {
  width: 170px;
  height: 60px;
  margin-left: 30px;
  margin-top: -70px;
}
[class^="col-"]:nth-child(2n) .ultra-card-header:after,
[class*=" col-"]:nth-child(2n) .ultra-card-header:after {
  width: 160px;
  height: 100px;
  margin-left: 70px;
  margin-top: 50px;
}
[class^="col-"]:nth-child(3n) .ultra-card-header:before,
[class*=" col-"]:nth-child(3n) .ultra-card-header:before {
  width: 160px;
  height: 120px;
  margin-left: 20px;
  margin-top: -20px;
}
[class^="col-"]:nth-child(3n) .ultra-card-header:after,
[class*=" col-"]:nth-child(3n) .ultra-card-header:after {
  width: 160px;
  height: 40px;
  margin-left: 70px;
  margin-top: 50px;
}
.ultra-card-header i {
  z-index: 1;
}
.ultra-card .ultra-card-header:before {
  transition: all ease-in-out .3s;
}
.ultra-card:hover .ultra-card-header:before {
  transform: rotate(-18deg) translateX(10px);
  transition: all ease-in-out 1s;
}
.ultra-card .ultra-card-header:after {
  transition: all ease-in-out .3s;
}
.ultra-card:hover .ultra-card-header:after {
  transform: rotate(-18deg) translateX(-10px);
  transition: all ease-in-out 1s;
}
.ultra-card .ultra-card-header i {
  transition: all ease-in-out .3s;
}
.ultra-card:hover .ultra-card-header i {
  transform: scale(1.2);
  transition: all ease-in-out 1s;
}
</style>