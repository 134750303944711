<template>
  <ul class="ultra-accordion-list" v-if="items">
    <li class="ultra-accordion-item" v-for="item in items" :key="item.id" data-aos="zoom-in-up" :ref="item.id">
      <header class="ultra-accordion-item-header"
              @click.prevent="toggleItem(item.id)"
              :class="{ active: item.id === active }"
      >
        <i v-if="item.icon" :class="`icon-${item.icon}`"></i>
        <h3 class="ml-20" v-if="item.title">{{ item.title }}</h3>
      </header>
      <section class="ultra-accordion-item-section" v-if="item.id === active" data-aos="slide-down">
        <UltraColText columns="two">
          <div class="img-rounded-highlight">
            <img class="img-rounded" :src="item.image" :alt="item.title" />
            <i v-if="item.icon" :class="`icon-${item.icon}`"></i>
          </div>
        </UltraColText>
        <UltraColText columns="two">
          <h2 class="mb-10" v-if="item.title">{{ item.title }}</h2>
          <p class="mb-20" v-if="item.description" v-html="item.description"></p>
          <UltraBtn :action="item.action" v-if="item.action" />
        </UltraColText>
      </section>
    </li>
  </ul>
</template>

<script>
import UltraColText from "@/components/UltraColText";
import UltraBtn from "@/components/UltraBtn";
import mixins from "@/mixins";

export default {
  name: "UltraAccordion",
  mixins: [mixins],
  data() {
    return {
      active: null,
    };
  },
  mounted() {
    this.initialState();
  },
  components: {UltraBtn, UltraColText},
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    toggleItem(item) {
      const { active } = this;
      this.active = active === item ? null : item;
      setTimeout(() => { this.setScroll() }, 500);
    },
    setScroll() {
      const { active } = this;
      if (!active) return;
      if (this.$refs[active]) this.scrollTopOnView(this.$refs[active][0]);
    },
    initialState() {
      const { items } = this;
      if (!items) return;
      this.active = items[0].id;
    },
  },
}
</script>

<style scoped>
.ultra-accordion-list {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  text-align: left;
  padding: 0;
  list-style: none;
}
.ultra-accordion-item {
  border: 1px solid var(--color-white);
  border-radius: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  text-align: left;
  padding: 0;
  box-shadow: 0 2px 4px rgba(255, 255, 255, .2);
}
.ultra-accordion-item-header {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  height: 60px;
  padding: 0 20px;
  position: relative;
  cursor: pointer;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, .2), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  background-size: 100% 200%;
  background-position: left bottom;
  transition: all ease-in-out .3s;
}
.ultra-accordion-item-header:hover {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, .2), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  background-size: 100% 200%;
  background-position: left top;
  transition: all ease-in-out .3s;
}
.ultra-accordion-item-header:before {
  content: '';
  width: 15px;
  height: 15px;
  border-bottom: 2px solid var(--color-white);
  border-left: 2px solid var(--color-white);
  position: absolute;
  right: 24px;
  top: 18px;
  transform: rotate(-45deg);
  transition: all ease-in-out .3s;
}
.ultra-accordion-item-header.active:before {
  top: 24px;
  transform: rotate(-225deg);
  transition: all ease-in-out .3s;
}

.ultra-accordion-item-section {
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  text-align: left;
  margin: 20px 20px 40px 20px;
}
@media only screen and (max-width: 600px) {
  .ultra-accordion-item-section {
    text-align: left;
  }
}
</style>