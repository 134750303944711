<template>
  <div class="ultra-image" v-if="image" @click.prevent="dispatchClick"
       :class="[{ withLink: image && image.action }, { withoutCaption: image && !image.caption }, { withMovie: image && image.movie }]">
    <img class="ultra-image-image" :src="image" :title="title ?? '...'" :alt="alt ?? '...'" v-if="typeof image === 'string'" />
    <div v-else class="ultra-image-wrapper">
      <img class="ultra-image-image" :src="image.src" v-if="image.src" :title="image.title ?? '...'" :alt="image.alt ?? '...'" />
      <div class="ultra-image-mask bg-color" :class="image.captionColor ?? 'secondary'" v-if="image.caption">
        <div class="ultra-image-caption">{{ image.caption }}</div>
      </div>
    </div>
    <div class="block-movie-button" data-aos="zoom-in" v-if="image.movie">
        <i class="icon-play-fill ultra-large"></i>
    </div>
  </div>
</template>

<script>
import mixins from "@/mixins";

export default {
  name: "UltraImage",
  mixins: [mixins],
  props: {
    image: {
      type: [Object, String],
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    alt: {
      type: String,
      required: false,
    },
  },
  methods: {
    dispatchClick() {
      const { action, movie } = this.image;
      if (action) {
        this.execAction(action);
      } else if (movie) {
        const movieAction = {
          icon: null,
          text: null,
          type: "modal",
          url: movie,
          target: null,
          options: {
            orientation: "landscape",
            title: null,
            icon: null
          },
        };
        this.execAction(movieAction);
      }
    },
  },
}
</script>

<style scoped>
.ultra-image {
  position: relative;
  display: inline-block;
  height: 100%;
  width: 100%;
}
.ultra-image.withLink {
  cursor: pointer;
}
.ultra-image.withMovie {
  cursor: pointer;
}
.ultra-image-image {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  display: inline-block;
  height: 100%;
  margin-bottom: 40px;
  margin-left: 10px;
  object-fit: cover;
  z-index: 1;
  width: 100%;
}
.withoutCaption .ultra-image-image {
  margin-left: 0;
}
.ultra-image-wrapper {
  width: 100%;
  height: auto;
  position: relative;
}
.ultra-image-mask {
  border-radius: 10px;
  position: absolute;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  height: calc(100% - 40px);
  text-align: left;
  width: 100%;
  top: 40px;
  left: -10px;
  background-color: var(--color-secondary);
}
.ultra-image-caption {
  padding: 0 0 12px 40px;
  color: var(--color-white);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.2rem;
  transition: all ease-in-out 1s;
}
.ultra-image .ultra-image-mask {
  transition: all ease-in-out 1s;
}
.ultra-image:hover .ultra-image-mask {
  transform: translateX(20px);
  transition: all ease-in-out 1.5s;
}
.ultra-image .ultra-image-image {
  transition: all ease-in-out 1.5s;
}
.ultra-image:not(.withoutCaption):hover .ultra-image-image {
  transform: translateX(-20px);
  transition: all ease-in-out 1s;
}
.ultra-image .ultra-image-caption {
  transition: all ease-in-out 1s;
}
.ultra-image:hover .ultra-image-caption {
  transform: translateX(-20px);
  transition: all ease-in-out 1s;
}
.block-movie-button {
  width: 200px;
  height: 200px;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: var(--color-white);
  cursor: pointer;
  transition: all ease-in-out .5s;
  top: calc((100% - 200px) / 2);
  left: calc((100% - 200px) / 2);
  z-index: 2;
}
.block-movie-button:before {
  content: '';
  display: inline-block;
  width: 164px;
  height: 164px;
  position: absolute;
  left: 18px;
  top: 18px;
  border-radius: 50%;
  background-color: var(--color-secondary);
  opacity: .6;
  transition: all ease-in-out .3s;
  pointer-events: none;
}
.block-movie-button:hover:before {
  opacity: .8;
  transform: scale(1.2);
  transition: all ease-in-out .3s;
}
</style>