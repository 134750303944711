<template>
  <a v-if="action"
     class="ultra-link"
     :class="[{ selected: action.selected ?? false }, { inactive: action.inactive ?? false }, { wrap: wrap }]"
     :href="action.url ?? '#'"
     :target="action.target ?? '_self'"
     @click.prevent="execAction(action)"
  >
    <i :class="[`icon-${action.icon}`, size]" v-if="action.icon"></i>
    <span v-else>{{ action.text }}</span>
    <span v-if="withText && action.icon">{{ action.text }}</span>
    <slot></slot>
  </a>
</template>

<script>
import mixins from "@/mixins";

export default {
  name: "UltraLink",
  mixins: [mixins],
  props: {
    action: {
      type: Object,
      required: false,
    },
    size: {
      type: String,
      required: false,
    },
    withText: {
      type: Boolean,
      default: false,
    },
    wrap: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
a.ultra-link {
  color: inherit;
  text-decoration: none;
  display: inline-flex;
  text-align: inherit;
  justify-content: center;
  font-weight: inherit;
  max-width: 100%;
}
a.ultra-link.inactive {
  pointer-events: none;
  opacity: .5;
}
a.ultra-link.selected {
  font-weight: 600;
}
.ultra-link i {
  pointer-events: none;
}
.ultra-link span {
  color: inherit;
  display: inline-block;
  font-weight: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}
.ultra-link.wrap span {
  white-space: unset;
}
.ultra-link i + span {
  margin-left: 10px;
}
</style>