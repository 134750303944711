<template>
  <section class="block-outdoor gb-section" v-if="outdoor">
    <img :src="outdoor.image" alt="slide-top" v-if="outdoor.image" data-aos="slide-down" />
    <div class="block-outdoor-wrapper gb-wrapper">
      <div class="block-outdoor-card" data-aos="zoom-in-left">
        <h2 class="mb-20 tx-uppercase" v-if="outdoor.title">{{ outdoor.title }}</h2>
        <p class="mb-20" v-if="outdoor.description">{{ outdoor.description }}</p>
        <UltraBtn :action="outdoor.action" />
      </div>
    </div>
  </section>
</template>

<script>
import UltraBtn from "@/components/UltraBtn";
export default {
  name: "BlockOutdoor",
  components: {UltraBtn},
  computed: {
    outdoor() {
      return this.$store.getters['outdoor'];
    },
  }
}
</script>

<style scoped>
.block-outdoor {
  overflow: hidden;
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  color: var(--color-white);
}
.block-outdoor > img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: inline-block;
}
.block-outdoor-wrapper {
  min-height: 560px;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.block-outdoor-card {
  width: 50%;
  text-align: left;
}
@media only screen and (max-width: 600px) {
  .block-outdoor-card {
    width: 100%;
    text-align: center;
  }
}
</style>