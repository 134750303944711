<template>
  <div class="ultra-content-item gb-section" v-if="content">
    <div class="ultra-content-item-wrapper" :class="{ 'gb-wrapper': !content.fullSize }">
      <div class="uci-header" v-if="content.title || content.description" data-aos="zoom-in-up">
        <h1 v-if="content.title" class="mb-20">{{ content.title }}</h1>
        <p v-if="content.description" class="mb-20">{{ content.description }}</p>
      </div>
    </div>
    <div class="ultra-content-item-wrapper" data-aos="zoom-in-up" :class="{ 'gb-wrapper': !content.fullSize }">
      <UltraColText :columns="content.template"
                    v-for="item in content.columns" :class="{ form: item.form }" :key="item.id">
        <h1 v-if="item.title" class="mb-20">{{ item.title }}</h1>
        <div v-if="item.description" class="mb-20 uci-text" v-html="item.description"></div>
        <UltraImage :image="item.image" v-if="!Array.isArray(item.image)" />
        <UltraCarousel v-else :slides="item.image" :itemsToShow="item.carouselItems ?? 1" class="fn-color"
                       :class="item.details ? item.details.textColor : 'secondary'" />
        <UltraAction :action="item.action" v-if="item.action" />
        <UltraForm :formContent="item.form ?? null" />
        <UltraArea :area="item.area ?? null" class="mb-20" />
      </UltraColText>
    </div>
  </div>
</template>

<script>
import UltraImage from "@/components/UltraImage";
import UltraAction from "@/components/UltraAction";
import UltraColText from "@/components/UltraColText";
import UltraCarousel from "@/components/UltraCarousel";
import UltraForm from "@/components/UltraForm";
import UltraArea from "@/components/UltraArea";
export default {
  name: "UltraContentItem",
  components: {UltraArea, UltraForm, UltraCarousel, UltraColText, UltraAction, UltraImage},
  props: {
    content: {
      type: Object,
      required: false,
    },
  },
}
</script>

<style scoped>
  .ultra-content-item {
    padding: 40px 0;
    overflow: visible;
  }
  .ultra-content-item-wrapper {
    flex-direction: row;
    flex-wrap: wrap;
    z-index: 1;
    position: relative;
  }
  .ultra-content-item-wrapper:not(.gb-wrapper) {
    padding: 0 40px;
  }
  .uci-text {
    display: inline-block;
    hyphens: manual;
    text-align: left;
    color: var(--color-black);
  }
  .ultra-content-item-wrapper h1,
  .uci-text h1,
  .uci-text h2,
  .uci-text h3,
  .uci-text h4,
  .uci-text h5,
  .uci-text h6 {
    color: var(--color-primary);
  }
  .form .uci-text {
    color: var(--color-white);
  }
  .ultra-content-item-wrapper .form h1,
  .form .uci-text h1,
  .form .uci-text h2,
  .form .uci-text h3,
  .form .uci-text h4,
  .form .uci-text h5,
  .form .uci-text h6 {
    color: var(--color-white);
  }
  .ultra-content-item-wrapper .form h1 {
    font-size: 1.9rem;
    line-height: 1.9rem;
    font-weight: 400;
  }
  .form {
    padding: 20px 60px 20px 20px;
  }
  .form:before {
    content: "";
    display: inline-block;
    position: absolute;
    left: -40px;
    top: -100px;
    width: calc(100% + 20px);
    height: calc(100% + 100px);
    border-radius: 10px;
    color: var(--color-white);
    background-image: linear-gradient(to bottom, rgba(29,35,87,.8) 40px, rgba(29,35,87,1) 100px);
    z-index: -1;
  }
  [class*='col-'] + .form {
    padding: 20px 0 20px 60px;
  }
  [class*='col-'] + .form:before {
    left: 20px;
    width: calc(100% + 20px);
  }
  @media only screen and (max-width: 600px) {
    .form {
      padding: 20px 20px 20px 20px;
    }
    .form:before {
      content: "";
      display: inline-block;
      position: absolute;
      left: -20px;
      top: -20px;
      width: calc(100% + 40px);
      height: calc(100% + 20px);
      border-radius: 10px;
      color: var(--color-white);
      background-image: linear-gradient(to bottom, rgba(29,35,87,.8) 40px, rgba(29,35,87,1) 100px);
      z-index: -1;
    }
    [class*='col-'] + .form {
      padding: 0;
    }
    [class*='col-'] + .form:before {
      left: -20px;
      width: calc(100% + 40px);
    }
  }
</style>