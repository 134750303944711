<template>
  <section class="ultra-slide gb-section" v-if="top && !Array.isArray(top)" :class="top.theme ?? 'default'">
    <img :src="top.image" alt="slide-top" v-if="top.image" data-aos="slide-down" />
    <div class="ultra-slide-wrapper gb-wrapper">
      <div class="ultra-slide-card" data-aos="zoom-in">
        <h1 class="mb-20 condensed" v-if="top.title">{{ top.title }}</h1>
        <p class="mb-20" v-if="top.description">{{ top.description }}</p>
        <UltraAction :action="top.action" v-if="top.action" class="ultra-slide-card-actions tx-align-right mb-20 fn-color black condensed" />
      </div>
    </div>
  </section>
  <section class="ultra-slide-carousel gb-section" v-else-if="top && Array.isArray(top)" :class="top.theme ?? 'default'">
    <Carousel :settings="settings" :breakpoints="breakpoints">
      <Slide v-for="item in top" :key="item.id">
        <div class="ultra-slide">
          <img :src="item.image" alt="slide-top" v-if="item.image" data-aos="slide-down" />
          <div class="ultra-slide-wrapper gb-wrapper">
            <div class="ultra-slide-card" data-aos="zoom-in">
              <h1 class="mb-20 condensed" v-if="item.title">{{ item.title }}</h1>
              <p class="mb-20" v-if="item.description">{{ item.description }}</p>
              <UltraAction :action="item.action" v-if="item.action" class="ultra-slide-card-actions tx-align-right mb-20 fn-color black condensed" />
            </div>
          </div>
        </div>
      </Slide>
      <template #addons>
        <Pagination class="ultra-slide-pagination" />
      </template>
    </Carousel>
  </section>
</template>

<script>
import { Carousel, Slide, Pagination } from 'vue3-carousel';
import UltraAction from "@/components/UltraAction";

export default {
  name: "UltraSlide",
  components: {
    UltraAction,
    Carousel,
    Slide,
    Pagination,
  },
  computed: {
    top() {
      return this.$store.getters['top'];
    },
    settings() {
      return {
        itemsToShow: 1,
        snapAlign: 'center',
        autoplay: 7000,
        pauseAutoplayOnHover: false,
        wrapAround: true,
      };
    },
    breakpoints() {
      return {
        600: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
      };
    },
  },
}
</script>

<style scoped>
.ultra-slide {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: var(--color-white);
  color: var(--color-white);
  overflow: visible;
  text-align: left;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.ultra-slide > img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: inline-block;
}
.ultra-slide:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
  z-index: 1;
  opacity: .6;
}
.ultra-slide:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: .8;
}
.ultra-slide-wrapper {
  padding: 90px 0 0 0;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
}
.ultra-slide-card {
  width: 50%;
  z-index: 2;
}
.ultra-slide-card h1 {
  font-size: 4rem;
  line-height: 4rem;
  color: var(--color-secondary);
  text-shadow: 0 3px 0 var(--color-secondary-dark);
  font-weight: 700;
}
.ultra-slide-card p {
  text-transform: uppercase;
  line-height: 1.2;
}

/* content */
.ultra-slide.content {
  height: auto;
  min-height: 240px;
}
.ultra-slide.content > img {
  position: relative;
  width: 940px;
  left: unset;
  top: unset;
  margin-top: 90px;
  height: 590px;
  border-radius: 10px;
}
.ultra-slide.content:before {
  display: none;
}
.ultra-slide.content:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 240px;
  background-image: linear-gradient(28deg, var(--color-primary-dark), var(--color-primary));
  z-index: 1;
  opacity: 1;
  mix-blend-mode: multiply;
  backdrop-filter: blur(5px);
}
.content .ultra-slide-card {
  width: 100%;
  height: 100%;
  z-index: 2;
  display: inline-flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row;
}
.content .ultra-slide-wrapper {
  position: absolute;
  padding: 90px 0 0 0;
  height: 240px;
  justify-content: center;
  align-items: flex-start;
}
.content .ultra-slide-card h1 {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.content .ultra-slide-card p {
  position: absolute;
  left: 0;
  top: 140px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: var(--color-black);
  opacity: .9;
}

@media only screen and (max-width: 600px) {
  .ultra-slide-card {
    width: 100%;
    flex-wrap: wrap;
  }
  .content .ultra-slide-card-actions {
    position: absolute;
    right: 0;
    top: 20px;
  }
  .content .ultra-slide-wrapper {
    padding: 130px 0 0 0;
    height: 280px;
    justify-content: center;
    align-items: flex-start;
  }
  .ultra-slide.content:after {
    height: 280px;
  }
}
</style>

<style>
.carousel__pagination.ultra-slide-pagination {
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 50px;
  height: 100%;
  color: white;
}
.ultra-slide-pagination .carousel__pagination-button {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: transparent;
  border: 2px solid currentColor;
}
.ultra-slide-pagination .carousel__pagination-button.carousel__pagination-button--active {
  background-color: currentColor;
  border: 2px solid currentColor;
}
</style>