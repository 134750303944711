<template>
  <div class="ultra-area" v-if="area">
    <iframe v-if="area.type === 'maps'" :src="area.url" :width="iframeWidth" :height="iframeHeight"
            style="border:0;" allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
    <iframe v-else-if="area.type === 'youtube'" :src="area.url" :width="iframeWidth" :height="iframeHeight"
            title="YouTube video player" style="border:0;"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
    <iframe v-else-if="area.type === 'vimeo'" :src="area.url" :width="iframeWidth" :height="iframeHeight"
            title="vimeo-player" style="border:0;" frameborder="0" allowfullscreen></iframe>
    <iframe v-else :src="area.url" :width="iframeWidth" :height="iframeHeight" style="border:0;"
            frameborder="0"></iframe>
  </div>
</template>

<script>
export default {
  name: "UltraArea",
  props: {
    area: {
      type: Object,
      required: false,
    },
  },
  computed: {
    iframeWidth() {
      const { attributes } = this.area;
      if (!attributes) return '100%';
      return attributes.width ? attributes.width : '100%';
    },
    iframeHeight() {
      const { attributes } = this.area;
      if (!attributes) return 400;
      return attributes.height ? attributes.height : 400;
    },
  },
}
</script>

<style scoped>
  .ultra-area {
    width: 100%;
    border: none;
  }
</style>