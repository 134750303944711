<template>
  <div class="ultra-btn bg-color"
       :class="[size, color, { 'fn-color': size === 'line' }, { inactive: inactive }, actionSize]"
       @click.prevent="execAction(action)" v-if="action">
    <div class="ultra-btn-icon" v-if="action && action.icon">
      <i :class="[`icon-${action.icon}`, size]" />
    </div>
    <div class="ultra-btn-text" v-if="action && action.text" :class="{ 'fn-color': size === 'text' }">
      {{ action ? action.text : '' }}
    </div>
    <div class="ultra-btn-text" v-else>
      <slot></slot>
    </div>
    <img :src="action.image" v-if="action.image" :alt="action.text" />
  </div>
  <div class="ultra-btn bg-color"
       :class="[size, color, { 'fn-color': size === 'line' }, { inactive: inactive }]" v-else>
    <div class="ultra-btn-text">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import mixins from "@/mixins";

export default {
  name: "UltraBtn",
  mixins: [mixins],
  props: {
    size: {
      type: String,
      default: 'normal',
    },
    color: {
      type: String,
      default: 'secondary',
    },
    action: {
      type: Object,
      required: false,
    },
    inactive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    actionSize() {
      const { action } = this;
      return action && action.size ? action.size : null;
    },
  },
}
</script>

<style scoped>
.ultra-btn {
  display: inline-flex;
  font-size: 1.4rem;
  line-height: 1;
  min-height: 60px;
  padding: 5px 30px;
  border: none;
  color: currentColor;
  transition: all ease-in-out .3s;
  cursor: pointer;
  width: auto;
  border-radius: 10px;
  font-weight: 700;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0 2px 0 currentColor;
  text-transform: uppercase;
}
.ultra-btn.inactive {
  filter: grayscale(100);
  pointer-events: none;
}
.ultra-btn.small {
  font-size: 1rem;
  line-height: 1rem;
  min-height: 40px;
  font-weight: 400;
  padding: 10px 25px;
  border: none;
  color: currentColor;
  border-radius: 10px;
  transition: all ease-in-out .3s;
}
.ultra-btn.image {
  font-size: .7rem;
  line-height: .7rem;
  font-weight: 400;
  padding: 0;
  border: none;
  color: currentColor;
  border-radius: 0;
  transition: all ease-in-out .3s;
  box-shadow: unset;
  width: 100%;
  min-width: 90px;
  text-align: center;
  position: relative;
  height: 100%;
}
.ultra-btn.image img {
  width: 100%;
  height: 100%;
  object-position: center center;
  object-fit: cover;
  display: inline-block;
}
.ultra-btn.line {
  border: 1px solid currentColor;
  border-radius: 10px;
  transition: all ease-in-out .3s;
  background-color: transparent !important;
  font-size: 1rem;
  line-height: 1rem;
  min-height: 40px;
  font-weight: 400;
  padding: 10px 25px;
}
.ultra-btn.text {
  border: none;
  border-radius: 0;
  transition: all ease-in-out .3s;
  background-color: transparent !important;
  font-size: 1rem;
  line-height: 1rem;
  min-height: unset;
  font-weight: 400;
  padding: 5px 0;
}
.ultra-btn-icon {
  margin-right: 10px;
  margin-top: 1px;
  margin-left: -10px;
}
.ultra-btn-text {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  text-align: center;
  white-space: nowrap;
}
.image .ultra-btn-text {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 8px;
}

.ultra-btn:hover {
  filter: brightness(1.40);
  transform: translate3d(0, 2px, 0);
  transition: all ease-in-out .3s;
  box-shadow: 0 0 0 currentColor;
}
.ultra-btn.text {
  box-shadow: unset;
}
.ultra-btn.text:hover {
  filter: brightness(1.40);
  transform: translate3d(0, 2px, 0);
  transition: all ease-in-out .3s;
  box-shadow: unset;
}
</style>