<template>
  <div class="block-fixed-buttons" v-if="buttons"
       data-aos="zoom-in-left">
    <UltraBtn
        :action="item.action"
        :color="item.color"
        size="small"
        class="mb-10"
        v-for="item in buttons"
        :key="item.id"
    />
  </div>
</template>

<script>
import UltraBtn from "@/components/UltraBtn";
export default {
  name: "BlockFixedButtons",
  components: {UltraBtn},
  computed: {
    buttons() {
      return this.$store.getters['buttons'];
    }
  },
}
</script>

<style scoped>
.block-fixed-buttons {
  position: fixed;
  right: 20px;
  bottom: 10px;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  color: var(--color-white);
  z-index: 2000;
}
</style>