<template>
  <div class="gb-section" v-if="professionals">
    <div class="gb-wrapper">
      <div class="ultra-professionals" data-aos="zoom-in">
        <label for="professionals-id" class="ultra-professionals-label">Pesquisar</label>
        <input id="professionals-id" name="value" placeholder="Digite para pesquisar..."
               class="ultra-professionals-input"
               @keyup="lossWithEnter"
               v-model="search" autocomplete="off" ref="inputSearch" />
        <button type="submit"
                @click.prevent="clearSearch"
                name="professionals-submit" class="ultra-professionals-button">Todos</button>
      </div>
      <div class="ultra-professionals-content">
        <ul class="ultra-professionals-list" v-if="filteredProfessionals && filteredProfessionals.length">
          <li class="ultra-professionals-item" :key="professional.id"
              data-aos="zoom-in"
              v-for="professional in filteredProfessionals">
            <UltraProfessionalsCard :professional="professional" />
          </li>
        </ul>
        <div class="ultra-professionals-empty" v-else>
          <p>Desculpe volte mais tarde, nenhum item no momento.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="area-copy" v-if="copied">
    <div class="copy-message" data-aos="zoom-in">
      <i class="icon-check-lg" data-aos="slide-up"></i>
      <p data-aos="slide-down" data-aos-delay="100">Copiado</p>
    </div>
  </div>
</template>

<script>
import mixins from "@/mixins";
import UltraProfessionalsCard from "@/components/UltraProfessionalsCard";

export default {
  name: "UltraProfessionals",
  components: {UltraProfessionalsCard},
  data() {
    return {
      search: '',
      copied: false,
    };
  },
  created() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    const { find } = params;
    if (find) this.search = find;
  },
  mixins: [mixins],
  props: {
    professionals: {
      type: Object,
      required: false,
    },
  },
  computed: {
    filteredProfessionals() {
      if (!this.search.length) return this.professionals.items;
      return this.professionals.items.filter(this.searchInResults);
    },
  },
  methods: {
    searchInResults(item) {
      const { search } = this;
      return Object.keys(item).some((value) => {
        if (!item[value]) return false;
        return item[value].toString().toLowerCase().indexOf(search.toLowerCase()) !== -1;
      });
    },
    lossWithEnter(event) {
      if (event.keyCode !== 13) return;
      this.lostFocus();
    },
    lostFocus() {
      const { inputSearch } = this.$refs;
      if (!inputSearch) return;
      inputSearch.blur();
    },
    clearSearch() {
      this.search = '';
      this.lostFocus();
    },
  },
}
</script>

<style scoped>
.ultra-professionals {
  position: relative;
  width: 100%;
  height: 120px;
  background-image: linear-gradient(30deg, rgba(30, 30, 30, .6), rgba(30, 30, 30, .8));
  margin-top: -80px;
  border-radius: 5px;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  backdrop-filter: blur(5px);
  padding: 20px;
}
.ultra-professionals-input {
  border-radius: 5px;
  padding: 5px 10px;
  border: none;
  margin: 10px;
  outline: none;
  font-size: 2rem;
  line-height: 2rem;
  background-color: transparent;
  color: var(--color-secondary);
  flex: 1;
}
.ultra-professionals-input::placeholder {
  color: rgba(255, 255, 255, .2);
}
.ultra-professionals-button {
  background-color: transparent;
  border: 1px solid currentColor;
  box-shadow: 0 2px 0 currentColor;
  color: rgba(255, 255, 255, .2);
  border-radius: 5px;
  padding: 8px 18px;
  cursor: pointer;
  transition: .3s ease-in-out all;
}
.ultra-professionals-input:focus + .ultra-professionals-button,
.ultra-professionals-button:hover {
  color: white;
  transition: .3s ease-in-out all;
  transform: translateY(1px);
  box-shadow: 0 0 0 currentColor;
}
.ultra-professionals-content {
  width: 100%;
  height: auto;
  padding: 40px 0;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
.ultra-professionals-empty {
  width: 100%;
  color: var(--color-gray);
  text-align: center;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 2px 6px var(--color-gray);
}
.ultra-professionals-list {
  width: 100%;
  height: auto;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 0;
  margin: 0;
  list-style: none;
}
.ultra-professionals-item {
  width: 100%;
  height: auto;
  position: relative;
  margin-bottom: 20px;
  padding: 0;
  border-radius: 10px;
  background-image: linear-gradient(-7deg, white 70%, var(--color-primary-dark) 110%);
  box-shadow: 0 4px 6px var(--color-gray);
  overflow: hidden;
}
</style>