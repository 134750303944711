const UltraFetch = async (url, data, method = 'post', csrf = null) => {

    let result = null;
    if (['post', 'POST'].includes(method))
        result = await post(url, data, csrf);

    if (['get', 'GET'].includes(method))
        result = await get(url, data, csrf);

    return result;
};

const post = async (url, data, csrf = null) => {

    // const body = new FormData();
    // Object.keys(data).map(keyName => {
    //     body.append(keyName, data[keyName]);
    // });

    const config = {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "X-Requested-With": "XMLHttpRequest",
        },
        method: 'post',
        credentials: 'same-origin',
        body: JSON.stringify(data),
    };

    if (csrf) config.headers = {
        ...config.headers,
        "X-CSRF-Token": csrf,
    }

    return execFetch(url, config);

};

const get = async (url, data, csrf = null) => {

    const body = '?' + new URLSearchParams(data).toString();

    const config = {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "X-Requested-With": "XMLHttpRequest",
        },
        method: 'get',
        credentials: 'same-origin',
    };

    if (csrf) config.headers = {
        ...config.headers,
        "X-CSRF-Token": csrf,
    }

    return execFetch(url + body, config);

};

const execFetch = async (url, config) => {
    let response = null;
    const result = {
        success: false,
        error: false,
        message: null,
        data: null,
        response: null,
    };
    try {
        response = await fetch(url, config);
    } catch (error) {
        result.error = true;
        result.message = error.message ?? '';
        result.response = error;
        return result;
    }
    const responseData = await response.json();
    if (!response || (response.status >= 400 && response.status < 600)) {
        result.error = true;
        result.message = response.statusText ?? ":( Resposta ruim do servidor.";
        result.response = response;
        return result;
    }
    if (response.status === 200) {
        result.success = true;
        result.message = responseData.data.message ?? '';
        result.response = responseData;
        result.data = responseData.data ? responseData.data.data ? responseData.data.data : responseData.data : null;
        return result;
    }

    result.error = true;
    result.message = responseData.data.message ?? '';
    result.response = responseData;
    return result;
};

export default UltraFetch;