import { createStore } from "vuex";

const store = createStore({
    state () {
        return {
            menu: null,
            social: null,
            signIn: null,
            top: null,
            items: null,
            highlight: null,
            modules: null,
            movie: null,
            drops: null,
            outdoor: null,
            comments: null,
            posts: null,
            services: null,
            buttons: null,
            footer: null,
            lgpd: null,
            iframe: null,
            professionals: null,
            businesses: null,
            calendars: null,
            brands: null,
            widgets: null,
            content: null,
            modal: {
                show: false,
                title: null,
                icon: null,
                url: null,
            },
            menuActive: false,
        }
    },
    getters: {
        menu(state) {
            return state.menu;
        },
        social(state) {
            return state.social;
        },
        signIn(state) {
            return state.signIn;
        },
        top(state) {
            return state.top;
        },
        items(state) {
            return state.items;
        },
        highlight(state) {
            return state.highlight;
        },
        modules(state) {
            return state.modules;
        },
        movie(state) {
            return state.movie;
        },
        drops(state) {
            return state.drops;
        },
        outdoor(state) {
            return state.outdoor;
        },
        comments(state) {
            return state.comments;
        },
        posts(state) {
            return state.posts;
        },
        services(state) {
            return state.services;
        },
        footer(state) {
            return state.footer;
        },
        lgpd(state) {
            return state.lgpd;
        },
        widgets(state) {
            return state.widgets;
        },
        buttons(state) {
            return state.buttons;
        },
        modal(state) {
            return state.modal;
        },
        iframe(state) {
            return state.iframe;
        },
        professionals(state) {
            return state.professionals;
        },
        businesses(state) {
            return state.businesses;
        },
        calendars(state) {
            return state.calendars;
        },
        brands(state) {
            return state.brands;
        },
        content(state) {
            return state.content;
        },
        menuActive(state) {
            return state.menuActive;
        },
    },
    mutations: {
        loader (state, payload) {
            const {
                page,
                menu,
                social,
                top,
                items,
                highlight,
                modules,
                movie,
                drops,
                outdoor,
                posts,
                services,
                comments,
                buttons,
                signIn,
                footer,
                lgpd,
                iframe,
                professionals,
                businesses,
                calendars,
                brands,
                widgets,
                content,
            } = payload;
            state.menu = page && page.menu === true ? menu : !page ? menu : null ;
            state.social = page && page.social === true ? social : !page ? social : null;
            state.signIn = page && page.signIn === true ? signIn : !page ? signIn : null;
            state.top = page && page.top === true ? top : !page ? top : null;
            state.items = page && page.items === true ? items : !page ? items : null;
            state.highlight = page && page.highlight === true ? highlight : !page ? highlight : null;
            state.modules = page && page.modules === true ? modules : !page ? modules : null;
            state.movie = page && page.movie === true ? movie : !page ? movie : null;
            state.drops = page && page.drops === true ? drops : !page ? drops : null;
            state.outdoor = page && page.outdoor === true ? outdoor : !page ? outdoor : null;
            state.comments = page && page.comments === true ? comments : !page ? comments : null;
            state.buttons = page && page.buttons === true ? buttons : !page ? buttons : null;
            state.posts = page && page.posts === true ? posts : !page ? posts : null;
            state.services = page && page.services === true ? services : !page ? services : null;
            state.footer = page && page.footer === true ? footer : !page ? footer : null;
            state.lgpd = page && page.lgpd === true ? lgpd : !page ? lgpd : null;
            state.widgets = page && page.widgets === true ? widgets : !page ? widgets : null;
            state.content = page && page.content === true ? content : !page ? content : null;
            state.iframe = page && page.iframe === true ? iframe : !page ? iframe : null;
            state.professionals = page && page.professionals === true ? professionals : !page ? professionals : null;
            state.businesses = page && page.businesses === true ? businesses : !page ? businesses : null;
            state.calendars = page && page.calendars === true ? calendars : !page ? calendars : null;
            state.brands = page && page.brands === true ? brands : !page ? brands : null;
        },
        showModal (state) {
            const { modal } = state;
            modal.show = true;
            state.modal = modal;
        },
        hideModal (state) {
            const { modal } = state;
            modal.show = false;
            state.modal = modal;
        },
        modal (state, payload) {
            state.modal = payload;
        },
        toggleMenu (state) {
            const { menuActive } = state;
            state.menuActive = !menuActive;
        },
        closeMenu (state) {
            state.menuActive = false;
        },
    },
    actions: {
        modal (context, payload) {
            const baseModal = {
                show: false,
                title: null,
                icon: null,
                url: null,
                orientation: 'portrait',
            };
            context.commit('modal', {
                ...baseModal,
                ...payload,
            });
            context.commit('showModal');
        },
    },
});

export default store;
