import getConfig from './config';

const datasource = {
  page: null,
  menu: null,
  social: null,
  signIn: null,
  top: null,
  items: null,
  highlight: null,
  modules: null,
  movie: null,
  drops: null,
  outdoor: null,
  comments: null,
  posts: null,
  services: null,
  buttons: null,
  footer: null,
  content: null,
  lgpd: null,
  iframe: null,
  widgets: null,
  professionals: null,
  businesses: null,
  calendars: null,
  brands: null,
};

const prepare = async () => {
  const config = await getConfig();
  return {
    ...datasource,
    ...config,
  };
};

export default prepare;
