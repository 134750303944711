import prepare from "@/datasource";
import AOS from "aos";

const mixins = {
    mounted() {
        AOS.init();
        this.initStore();
        this.initScrollController();
        this.initRefreshController();
    },
    beforeUnmount() {
        this.destroyScrollController();
        this.destroyRefreshController();
    },
    methods: {
        async initStore() {
            const datasource = await prepare();
            this.$store.commit('loader', datasource);
        },
        initScrollController() {
            window.addEventListener('canScroll', this.canScroll);
        },
        destroyScrollController() {
            window.removeEventListener('canScroll', this.canScroll);
        },
        initRefreshController() {
            window.addEventListener('refreshStore', this.initStore);
        },
        destroyRefreshController() {
            window.removeEventListener('refreshStore', this.initStore);
        },
        execAction(action = null) {
            if (!action) {
                this.sendClick();
                return;
            }
            setTimeout(() => {
                this.$store.commit('closeMenu');
            }, 300);
            const { type } = action;
            switch (type) {
                case 'link':
                    this.openLink(action);
                    return;

                case 'scroll':
                    this.scrollTo(action);
                    return;

                case 'modal':
                    this.openModal(action);
                    return;

                case 'post':
                case 'POST':
                case 'get':
                case 'GET':
                    this.postForm(action);
                    return;

                default:
                    this.sendClick();
                    return;
            }
        },
        postForm(action) {
            this.$emit('post', action);
        },
        sendClick() {
            this.$emit('click');
        },
        openLink({ url, target }) {
            window.open(url, target ?? '_blank');
        },
        scrollTo({ url }) {
            const eventScroll = new CustomEvent('canScroll', { detail: url });
            window.dispatchEvent(eventScroll);
        },
        openModal({ url, options }) {
            const { title, icon, orientation, } = options ?? { title: null, icon: null };
            const payload = {
                title: title ?? null,
                icon: icon ?? null,
                url: url ?? null,
                orientation: orientation ?? 'portrait',
            };
            this.$store.dispatch('modal', payload);
        },
        hideModal() {
            this.$store.commit('hideModal');
        },

        canScroll({ detail }) {
            if (!detail) return;
            if (this.$refs[detail]) this.scrollTopOnView(this.$refs[detail].$el);
        },
        scrollTopOnView(element) {
            const offset = -90;
            const yTop = element.getBoundingClientRect().top + window.pageYOffset + offset;
            window.scrollTo({ top: yTop, behavior: "smooth" });
        },
        openHome() {
            const action = {
                url: '/',
                target: '_self',
            };
            this.openLink(action);
        },
        copyToClipboard(text) {
            if (navigator && navigator.clipboard && navigator.clipboard.writeText)
                return navigator.clipboard.writeText(text);
            return false;
        },
    }
};

export default mixins;