<template>
  <header class="ultra-header gb-section" :class="{ active: active }">
    <section class="ultra-header-wrapper gb-wrapper">
      <div class="ultra-header-logo" ref="boxLogo" :style="boxLogoStyles" @click="openHome">
        <img src="../assets/images/acivg-logo.png" alt="Infinity" />
      </div>
      <div class="ultra-header-menu" v-if="menu || social" :class="{ active: menuActive }">
        <ul class="ultra-header-menu-list" v-if="menu && menu.length" data-aos="zoom-in-up">
          <UltraItemLinkWithSubMenu :item="item" v-for="item in menu" :key="item.id" />
        </ul>
        <ul class="ultra-header-menu-social" v-if="social && social.length" data-aos="zoom-in-up">
          <UltraItemLink :action="item.action" v-for="item in social" :key="item.id ?? item.icon" />
        </ul>
        <ul class="ultra-header-menu-signIn" v-if="signIn" data-aos="zoom-in-up">
          <UltraBtn :action="signIn.action" />
        </ul>
        <div class="ultra-header-menu-mobile_action_menu" @click.prevent="toggleMenu">
          <i class="icon-menu" data-aos="zoom-in-left" v-if="!menuActive"></i>
          <i class="icon-x" data-aos="zoom-in-left" v-else></i>
        </div>
      </div>
    </section>
  </header>
</template>

<script>
import UltraBtn from "@/components/UltraBtn";
import UltraItemLink from "@/components/UltraItemLink";
import mixins from "@/mixins";
import UltraItemLinkWithSubMenu from "@/components/UltraItemLinkWithSubMenu";

export default {
  name: "UltraHeader",
  mixins: [mixins],
  components: {UltraItemLinkWithSubMenu, UltraItemLink, UltraBtn},
  data() {
    return {
      min: 90,
      max: 180,
      scroll: null,
    };
  },
  computed: {
    menu() {
      return this.$store.getters['menu'];
    },
    menuActive() {
      return this.$store.getters['menuActive'];
    },
    social() {
      return this.$store.getters['social'];
    },
    signIn() {
      return this.$store.getters['signIn'];
    },
    boxLogoStyles() {
      const { min, max, scroll } = this;
      const middleValue = (scroll === 0) ? max : ((scroll / 2) < min) ? max - (scroll / 2) : min;
      return {
        maxHeight: `${middleValue}px`,
        marginTop: `${middleValue - 90}px`,
      };
    },
    active() {
      const { min, scroll } = this;
      return scroll >= min;
    },
  },
  mounted() {
    this.startScrollControl();
  },
  beforeUnmount() {
    this.finishScrollControl();
  },
  methods: {
    startScrollControl() {
      const { boxLogo } = this.$refs;
      if (!boxLogo) return;
      document.addEventListener('scroll', this.checkScrollControl);
    },
    checkScrollControl() {
      this.scroll = window.top.scrollY;
    },
    finishScrollControl() {
      document.removeEventListener('scroll', this.checkScrollControl);
    },
    toggleMenu() {
      this.$store.commit('toggleMenu');
    },
  },
}
</script>

<style scoped>
.ultra-header {
  height: auto;
  min-height: 90px;
  position: fixed;
  top: 0;
  color: var(--color-white);
  z-index: 1000;
}
.ultra-header:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 90px;
  background-color: transparent;
  transition: background-color .5s ease-in-out;
  background-image: linear-gradient(28deg, var(--color-primary-dark), var(--color-primary));
  backdrop-filter: blur(10);
  opacity: 1;
}
.ultra-header-wrapper {
  flex-direction: row;
  justify-content: space-between;
}
.ultra-header-logo {
  width: auto;
  max-height: 180px;
  height: auto;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  cursor: pointer;
  margin-top: 90px;
}
.ultra-header-logo > img {
  height: 100%;
  background-color: white;
  padding: 15px;
}
.ultra-header-menu {
  position: relative;
  width: auto;
  flex: 1;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 90px;
}
.ultra-header-menu-list {
  position: relative;
  width: auto;
  flex: 1;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 90px;
  list-style: none;
  padding: 0;
  margin: 0;
}
.ultra-header-menu-social {
  position: absolute;
  width: auto;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  list-style: none;
  padding: 0 0 0 10px;
  margin: 0;
  top: 90px;
}
.ultra-header-menu-social .item-link {
  color: var(--color-secondary);
}
.ultra-header-menu-signIn {
  position: relative;
  width: auto;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 90px;
  list-style: none;
  padding: 0 0 0 10px;
  margin: 0;
  overflow: hidden;
}
.item-link {
  padding: 5px;
  text-transform: uppercase;
  font-weight: 400;
  color: var(--color-white);
  transition: color ease-in-out .3s;
  cursor: pointer;
  width: auto;
  overflow: hidden;
}
.item-link:hover {
  color: var(--color-secondary-light);
  transition: color ease-in-out .3s;
}
.item-link > a {
  color: inherit;
  text-decoration: none;
  display: inline-block;
  text-align: center;
}
.item-link > a > i {
  pointer-events: none;
}
.ultra-header-menu-mobile_action_menu {
  position: relative;
  width: auto;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 90px;
  display: none;
}
@media only screen and (max-width: 600px) {
  .ultra-header-menu {
    position: fixed;
    top: 100px;
    left: 10px;
    width: calc(100vw - 20px);
    min-height: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    background-color: var(--color-primary-dark);
    flex-direction: column;
    border-radius: 20px;
    pointer-events: none;
    transition: all ease-in-out .3s;
    border-bottom: none;
  }
  .ultra-header-menu.active {
    min-height: calc(100vh - 110px);
    padding: 20px 0;
    transition: all ease-in-out .3s;
  }
  .ultra-header-menu-list {
    width: 100%;
    height: auto;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    pointer-events: all;
  }
  .ultra-header-menu-list {
    width: 100%;
    height: auto;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    pointer-events: all;
  }
  .ultra-header-menu-social {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
    padding: 0;
    pointer-events: all;
  }
  .ultra-header-menu-signIn {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
    padding: 0;
    pointer-events: all;
  }
  .ultra-header-logo {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .ultra-header-menu-mobile_action_menu {
    position: fixed;
    top: 0;
    left: 0;
    height: 90px;
    width: 60px;
    display: inline-flex;
    justify-content: center;
    pointer-events: all;
  }
  .item-link {
    min-height: 60px;
  }
}
</style>