<template>
  <section class="block-modules gb-section" v-if="modules">
    <div class="block-modules-wrapper gb-wrapper">
      <h2 class="mb-20 tx-uppercase" data-aos="zoom-in-down">{{ modules.title }}</h2>
      <h3 class="mb-20" data-aos="zoom-in-down">{{ modules.description }}</h3>
      <UltraAccordion :items="modules.items" />
    </div>
  </section>
</template>

<script>
import UltraAccordion from "@/components/UltraAccordion";

export default {
  name: "BlockModules",
  components: {UltraAccordion},
  computed: {
    modules() {
      return this.$store.getters['modules'];
    },
  },
}
</script>

<style scoped>
.block-modules {
  background-image: linear-gradient(74.24deg, #2E2E84 10.43%, #0c0c48 93.74%);
  color: var(--color-white);
}
.block-modules-wrapper {
  text-align: center;
  padding: 80px 0;
}
</style>