<template>
  <div class="ultra-form" v-if="formContent">
    <form class="ultra-form-wrapper" ref="formExecutor" :action="formContent.action.url ?? '/'" :method="formContent.action.type ?? 'GET'">

      <input v-if="formContent.crlfToken" ref="crlfToken" type="hidden" name="_token" :value="formContent.crlfToken" />
      <FormDownload ref="formElement" v-if="formContent.template === 'download'" />
      <FormContact ref="formElement" v-if="formContent.template === 'contact'" />
      <div class="ultra-form-message error" v-if="error" data-aos="zoom-in-up">{{ errorMessage ?? formContent.error }}</div>
      <div class="ultra-form-message success" v-if="success" data-aos="zoom-in-up">{{ successMessage ?? formContent.success }}</div>
      <div class="ultra-form-loader" v-if="loading" data-aos="zoom-in-down"></div>
      <UltraBtn
          :action="formContent.action"
          :size="formContent.action.size"
          @post="sendForm"
          :inactive="loading"
          :color="formContent.action.color">Enviar</UltraBtn>

    </form>
  </div>
</template>

<script>
import UltraBtn from "@/components/UltraBtn";
import FormDownload from "@/components/forms/FormDownload";
import FormContact from "@/components/forms/FormContact";
import UltraFetch from "@/datasource/UltraFetch";

export default {
  name: "UltraForm",
  data() {
    return {
      success: false,
      successMessage: '',
      error: false,
      errorMessage: '',
      loading: false,
    };
  },
  components: {FormContact, FormDownload, UltraBtn},
  props: {
    formContent: {
      type: Object,
      required: false,
    },
  },
  methods: {
    async sendForm() {
      this.success = false;
      this.error = false;
      const { loading, formContent } = this;
      const { formElement, formExecutor } = this.$refs;
      if (loading && !formElement && !formExecutor) return;
      if (!formElement.checkForm()) return;

      this.loading = true;
      const data = formElement.get();
      if (!data) {
        this.setError();
        return;
      }
      if (formContent.hashFile) data.document = formContent.hashFile;
      if (formContent.formId) data.formId = formContent.formId;
      if (formContent.formPayload) data.formPayload = formContent.formPayload;

      const result = await UltraFetch(formContent.action.url, data, formContent.action.type, formContent.crlfToken);
      if (result && result.success) {
        this.setSuccess(result.message);
        formElement.reset();
      }
      if (!result || result.error) {
        this.setError(result.message);
      }
    },
    setError(message = '') {
      this.loading = false;
      this.errorMessage = message;
      this.error = true;
    },
    setSuccess(message = '') {
      this.loading = false;
      this.successMessage = message;
      this.success = true;
    },
  },
}
</script>

<style scoped>
.ultra-form {
  width: 100%;
  margin-bottom: 20px;
  color: var(--color-white);
}
.ultra-form-message {
  width: 100%;
  padding: 5px 10px 4px 10px;
  border-radius: 5px;
  background-color: var(--color-black);
  margin-bottom: 10px;
  font-size: .8rem;
  line-height: .8rem;
  font-weight: 500;
}
.ultra-form-message.success {
  background-color: var(--color-primary-light);
}
.ultra-form-message.error {
  background-color: darkred;
}
@keyframes progress {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
.ultra-form-loader {
  width: calc(100% + 20px);
  height: 10px;
  background-image: linear-gradient(to left, transparent, var(--color-primary-light), transparent);
  background-size: 200% 100%;
  background-position: left top;
  position: absolute;
  bottom: 0;
  left: 20px;
  border-radius: 0 0 20px 20px;
  animation: progress ease-in-out infinite .6s alternate-reverse;
}
</style>