<template>
  <footer class="block-footer gb-section" v-if="footer">
    <div class="block-footer-wrapper gb-wrapper">
      <div class="block-footer-header">
        <div class="block-footer-header-logo" @click="openHome">
          <img src="@/assets/images/acivg-logo.png" alt="logo" />
        </div>
        <div class="block-footer-header-menu" v-if="menu">
          <ul class="block-footer-header-menu-list horizontal" v-if="menu && menu.length">
            <UltraItemLinkWithSubMenu :item="item" position="top" v-for="item in menu" :key="item.id" />
          </ul>
        </div>
      </div>
    </div>
    <div class="block-footer gb-section footer-actions">
      <div class="gb-wrapper">
        <div class="block-footer-content">
          <div class="block-footer-content-actions" v-if="(footer && footer.action) || social">
            <ul class="block-footer-content-actions-action" v-if="footer && footer.action && footer.action.length">
              <UltraItemLink :withText="true" class="mr-20" :action="item" v-for="item in footer.action" :key="item.id" />
            </ul>
            <ul class="block-footer-content-actions-social" v-if="social.length">
              <UltraItemLink :action="item.action" v-for="item in social" :key="item.id" />
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="block-footer-wrapper address gb-wrapper">
      <div class="block-footer-content">
        <div class="block-footer-content-addresses" v-if="footer.address">
          <div class="block-footer-content-address mb-10" v-for="item in footer.address" :key="item.id">
            <UltraLink :action="item.action" :wrap="true" v-if="item.action" />
            <p v-else>{{ item }}</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <UltraPrivacyMessage />
</template>

<script>
import UltraLink from "@/components/UltraLink";
import UltraItemLink from "@/components/UltraItemLink";
import mixins from "@/mixins";
import UltraPrivacyMessage from "@/components/UltraPrivacyMessage";
import UltraItemLinkWithSubMenu from "@/components/UltraItemLinkWithSubMenu";

export default {
  name: "BlockFooter",
  mixins: [mixins],
  components: {UltraItemLinkWithSubMenu, UltraPrivacyMessage, UltraItemLink, UltraLink},
  computed: {
    footer() {
      return this.$store.getters['footer'];
    },
    social() {
      const { useRootSocial, social } = this.footer;
      if (useRootSocial) return this.$store.getters['social'];
      if (social) return social;
      return null;
    },
    menu() {
      const { useRootMenu, menu } = this.footer;
      if (useRootMenu) return this.$store.getters['menu'];
      if (menu) return menu;
      return null;
    },
    menuOnlyLink() {
      return this.menu ? this.menu.filter(items => !items.submenu) : null;
    },
    menuWithSubmenu() {
      return this.menu ? this.menu.filter(items => items.submenu && items.submenu.length) : [];
    },
  },
}
</script>

<style scoped>
.block-footer {
  background-color: var(--color-gray);
  color: var(--color-black);
  padding-top: 10px;
  position: relative;
}
.block-footer:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 10px;
  background-image: linear-gradient(28deg, var(--color-primary-dark), var(--color-primary));
}
.block-footer-wrapper {
  flex-direction: column;
  justify-content: stretch;
  align-items: flex-start;
}
.block-footer-wrapper.address {
  padding-top: 10px;
  padding-bottom: 150px;
}
.block-footer-header {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.block-footer-content {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
.block-footer-header-menu {
  width: 75%;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 15px;
}
.block-footer-header-logo {
  width: auto;
  max-height: 180px;
  height: auto;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  cursor: pointer;
}
.block-footer-header-logo > img {
  height: 100%;
  background-color: white;
  padding: 15px;
  z-index: 1;
}
.block-footer-header-menu-list {
  width: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  display: inline-flex;
}
.block-footer-header-menu-list.horizontal {
  flex-direction: row;
}
.block-footer-content-actions {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 40px 0;
  flex-wrap: wrap;
}
.footer-actions {
  margin-top: -15px;
  background-image: linear-gradient(28deg, var(--color-primary-dark), var(--color-primary));
  color: var(--color-white);
}
.block-footer-content-actions-action,
.block-footer-content-actions-social {
  width: auto;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
}

@media only screen and (max-width: 600px) {
  .block-footer-header {
    flex-direction: column;
  }
  .block-footer-header-logo {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  .block-footer-header-menu {
    width: 100%;
  }
  .block-footer-content-actions {
    flex-direction: column;
    align-items: center;
  }
  .block-footer-content-actions-action {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .block-footer-content-addresses {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    flex-direction: column;
  }
  .block-footer-content-address {
    text-align: center;
  }
}
</style>