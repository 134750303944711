<template>
  <div class="upm-block" v-if="show && message">
    <div class="upm-wrapper" data-aos="slide-in-up">
      <div class="upm-container">
        <div class="upm-text">{{ message.description }}</div>
      </div>
      <div class="upm-actions">
        <UltraBtn color="secondary-light" @click="acceptCookies">Aceito</UltraBtn>
        <UltraAction class="ml-20" :action="message.action" v-if="message.action" />
      </div>
    </div>
  </div>
</template>

<script>
import UltraBtn from "@/components/UltraBtn";
import UltraAction from "@/components/UltraAction";

export default {
  name: "UltraPrivacyMessage",
  data() {
    return {
      show: false,
      description: 'Nós armazenamos dados temporariamente para melhorar a sua experiência de navegação e recomendar conteúdo de seu interesse. Ao utilizar nossos serviços, você concorda com tal monitoramento.',
    };
  },
  computed: {
    message() {
      const lpdg = this.$store.getters['lgpd'];
      if (lpdg) return lpdg;
      return {
        description: this.description,
      };
    },
  },
  mounted() {
    this.checkCookieExists();
  },
  components: {
    UltraAction,
    UltraBtn,
  },
  methods: {
    acceptCookies() {
      this.setCookie('lgpd-cookie', 'true', 7);
      this.show = false;
    },
    checkCookieExists() {
      const lgpdCookie = this.getCookie('lgpd-cookie');
      if (lgpdCookie === 'true') return;
      this.show = true;
    },

    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    setCookie(cName, cValue, exDays) {
      const d = new Date();
      d.setTime(d.getTime() + (exDays * 24 * 60 * 60 * 1000));
      let expires = "expires=" + d.toUTCString();
      document.cookie = cName + "=" + cValue + ";" + expires + ";path=/";
    },
  },
}
</script>

<style scoped>
.upm-block {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.upm-wrapper {
  background: var(--color-primary-dark);
  border-radius: 20px;
  max-width: 900px;
  width: 100%;
  height: auto;
  padding: 20px;
  color: var(--color-white);
}
.upm-container {
  font-size: 1.2rem;
  margin-bottom: 20px;
}
</style>