<template>
  <div class="ultra-professionals-card" v-if="professional">
    <div class="ultra-professionals-card-content">
      <div class="ultra-professionals-card-title">
        {{ professional.title }}
        <span v-if="professional.register">{{ professional.register }}</span>
      </div>
      <ul class="ultra-professionals-card-specialities" v-if="professional.specialities && professional.specialities.length" >
        <li v-for="speciality in professional.specialities"
            @click.prevent="selectSpeciality(speciality)"
            :key="`${professional.id}_${speciality}`" >{{ speciality }}</li>
      </ul>
      <div class="ultra-professionals-card-actions" v-if="professional.phones">
        <div class="ultra-professionals-card-group">
          <button class="ultra-professionals-card-actions_action"
                  @click.prevent="openCopy(professional.phones.default)"
                  v-if="professional.phones.default.number">{{ professional.phones.default.number }}
            <i class="icon-copy small"></i></button>
          <button class="ultra-professionals-card-actions_action"
                  @click.prevent="openAction(professional.phones.default)"
                  v-if="professional.phones.default.number">
            <i class="icon-call-made small"></i>
          </button>
        </div>

        <div class="ultra-professionals-card-group">
          <button class="ultra-professionals-card-actions_action"
                  @click.prevent="openCopy(professional.phones.cell)"
                  v-if="professional.phones.cell.number">{{ professional.phones.cell.number }}
            <i class="icon-copy small"></i></button>
          <button class="ultra-professionals-card-actions_action"
                  @click.prevent="openAction(professional.phones.cell)"
                  v-if="professional.phones.cell.number">
            <i class="icon-call-made small"></i>
          </button>
        </div>

        <div class="ultra-professionals-card-group">
          <button class="ultra-professionals-card-actions_action"
                  @click.prevent="openCopy(professional.phones.whatsapp)"
                  v-if="professional.phones.whatsapp.number">{{ professional.phones.whatsapp.number }}
            <i class="icon-copy small"></i></button>
          <button class="ultra-professionals-card-actions_action"
                  @click.prevent="openAction(professional.phones.whatsapp)"
                  v-if="professional.phones.whatsapp.number">
            <i class="icon-call-made small"></i>
          </button>
          <button class="ultra-professionals-card-actions_action"
                  @click.prevent="openMessage(professional.phones.whatsapp)"
                  v-if="professional.phones.whatsapp.number">
            <i class="icon-whatsapp small"></i>
          </button>
        </div>
      </div>
      <div class="ultra-professionals-card-actions" v-if="professional.address.text">
        <div class="ultra-professionals-card-group">
          <button class="ultra-professionals-card-actions_action"
                  @click.prevent="openCopy(professional.address)"
                  v-if="professional.address.text"
          >{{ professional.address.text }}
            <i class="icon-copy small"></i></button>
          <button class="ultra-professionals-card-actions_action"
                  @click.prevent="openLocation(professional.address)"
                  v-if="professional.address.text">
            <i class="icon-location small"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="ultra-professionals-card-image" v-if="professional.image">
      <img :src="professional.image" loading="lazy" :alt="professional.title" />
    </div>
  </div>
</template>

<script>
import mixins from "@/mixins";

export default {
  name: "UltraProfessionalsCard",
  mixins: [mixins],
  props: {
    professional: {
      type: Object,
      required: false,
    },
  },
  methods: {
    selectSpeciality(speciality) {
      if (!speciality) return;
      this.search = speciality;
    },
    openAction(phone) {
      const { action } = phone;
      if (!action) return;
      this.openLink({
        action,
      });
    },
    openMessage(phone) {
      const { message } = phone;
      if (!message) return;
      this.openLink({
        url: message,
      });
    },
    openCopy(info) {
      const { text, number } = info;
      if (text) this.copyToClipboard(text);
      if (number) this.copyToClipboard(number);
      this.copied = true;
      setTimeout(() => this.copied = false, 2000);
    },
    openLocation(location) {
      const { action } = location;
      if (!action) return;
      this.openLink({
        action,
      });
    },
  },
}
</script>

<style scoped>
.ultra-professionals-card {
  width: 100%;
  height: auto;
  display: inline-flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
}
.ultra-professionals-card-image {
  width: 200px;
  background-color: #2E2E84;
  position: relative;
}
.ultra-professionals-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
}
.ultra-professionals-card-content {
  width: 100%;
  padding: 20px 0 0 20px;

  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  font-size: .8rem;
  line-height: .8rem;
}

.ultra-professionals-card-title {
  font-size: 1.2rem;
  line-height: 1.2rem;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-weight: 700;
}
.ultra-professionals-card-title span {
  display: inline-block;
  margin-left: 10px;
  font-size: .8rem;
  line-height: .8rem;
  background-color: var(--color-gray);
  padding: 5px 15px;
  border-radius: 10px;
  color: white;
}
.ultra-professionals-card-specialities {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 5px 0 0 0;
  list-style: none;
}
.ultra-professionals-card-specialities li {
  padding: 3px 0;
  margin-right: 10px;
  border-bottom: 2px solid transparent;
  transition: all .8s linear;
  cursor: pointer;
}
.ultra-professionals-card-specialities li:hover {
  border-bottom: 2px solid var(--color-primary);
  transition: all .3s linear;
}
.ultra-professionals-card-actions {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  padding: 5px 10px;
  margin: 0 0 0 -20px;
  list-style: none;
  width: calc(100% + 20px);
  font-size: 1rem;
  line-height: 1rem;
  border-top: 1px solid var(--color-gray);
}
.ultra-professionals-card-group {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-items: center;
}
.ultra-professionals-card-actions_action {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all .3s linear;
}
.ultra-professionals-card-actions_action i {
  margin-top: -2px;
}
.ultra-professionals-card-actions_action:hover {
  background-color: var(--color-secondary-light);
  transition: all .3s linear;
}

.area-copy {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 1000;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
.copy-message {
  width: 200px;
  height: 200px;
  background-color: rgba(40, 40, 40, .8);
  color: white;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  text-transform: uppercase;
}
.copy-message i {
  border: 2px solid white;
  padding: 10px;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {
  .ultra-professionals-card-image {
    display: none;
  }
}
</style>