<template>
  <UltraBox class="block-social" theme="secondary" v-if="social">
    <ul class="block-social-social" v-if="social.length" data-aos="zoom-in-up">
      <li class="item-link" v-for="item in social" :key="item.icon">
        <UltraLink :action="item.action" size="ultra-large" />
      </li>
    </ul>
  </UltraBox>
</template>

<script>
import UltraBox from "@/components/UltraBox";
import UltraLink from "@/components/UltraLink";
export default {
  name: "BlockSocial",
  components: {
    UltraLink,
    UltraBox,
  },
  computed: {
    social() {
      return this.$store.getters['social'];
    },
  },
}
</script>

<style scoped>
.block-social-social {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  list-style: none;
  padding: 60px 0;
  flex-wrap: wrap;
}
.block-social-social .item-link {
  color: var(--color-white);
}
.item-link {
  padding: 20px 40px;
  text-transform: uppercase;
  font-weight: 400;
  color: var(--color-white);
  transition: color ease-in-out .3s;
  cursor: pointer;
  width: auto;
  overflow: hidden;
}
.item-link:hover {
  color: var(--color-secondary-light);
  transition: color ease-in-out .3s;
}
.item-link > a {
  color: inherit;
  text-decoration: none;
  display: inline-block;
  text-align: center;
}
.item-link > a > i {
  pointer-events: none;
}
</style>