<template>
  <UltraHighlightPages :highlight="highlight" />
</template>

<script>
import UltraHighlightPages from "@/components/UltraHighlightPages";

export default {
  name: "BlockHighlightPage",
  components: {UltraHighlightPages},
  computed: {
    highlight() {
      return this.$store.getters['highlight'];
    },
  },
}
</script>

<style scoped>

</style>