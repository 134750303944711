<template>
  <div class="ultra-card-post" v-if="post"
       :class="[modifier]"
       @click="execAction(post.action)">
    <div class="ultra-card-post-thumb">
      <div class="ultra-card-post-thumb-wrapper">
        <UltraImage :image="post.image" />
      </div>
    </div>
    <header class="ultra-card-post-header">
      <h3 class="fn-color primary" v-if="post.title">{{ post.title }}</h3>
      <p class="mt-10" v-if="post.description">{{ post.description }}</p>
    </header>
  </div>
</template>

<script>
import UltraImage from "@/components/UltraImage";
import mixins from "@/mixins";

export default {
  name: "UltraCardPost",
  mixins: [mixins],
  components: {UltraImage},
  props: {
    post: {
      type: Object,
      required: true,
    },
    modifier: {
      type: String,
      default: 'default',
    },
  }
}
</script>

<style scoped>
.ultra-card-post {
  width: calc(100% + 20px);
  text-align: center;
  position: relative;
  border-radius: 20px;
  margin-left: -10px;
  margin-bottom: 10px;
  transition: all .3s ease-in-out;
  cursor: pointer;
  display: inline-flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: row;
  padding: 10px;
  color: var(--color-primary-dark);
}
.ultra-card-post:hover {
  background-color: var(--color-primary-dark);
  color: white;
  transition: all .3s ease-in-out;
}
.ultra-card-post-header {
  position: relative;
  text-align: left;
  padding: 0 0 0 20px;
  width: 100%;
  display: inline-flex;
  justify-content: flex-end;
  align-items: stretch;
  flex-direction: column;
}
.ultra-card-post-header h3 {
  font-size: 2rem;
  line-height: 2rem;
  letter-spacing: -1px;
  color: currentColor !important;
}
.ultra-card-post-header p {
  font-size: 1rem;
  line-height: 1.2rem;
}
.ultra-card-post-section {
  padding: 0 10px;
  margin-bottom: 10px;
  position: relative;
  text-align: left;
}
.list .ultra-card-post-thumb {
  width: 30%;
  padding-bottom: 15%;
}
.ultra-card-post-thumb {
  width: 100%;
  height: auto;
  padding-bottom: 20%;
  position: relative;
}
.ultra-card-post-thumb-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
</style>