<template>
  <div class="ultra-content gb-section" v-if="content && Array.isArray(content)">
    <UltraContentItem
        :content="item"
        v-for="item in content" :key="item.id" />
  </div>
  <div class="ultra-content gb-section" v-else-if="content">
    <UltraContentItem :content="content" />
  </div>
</template>

<script>
import UltraContentItem from "@/components/UltraContentItem";
export default {
  name: "UltraContent",
  components: {UltraContentItem},
  computed: {
    content() {
      return this.$store.getters['content'];
    },
  },
}
</script>

<style scoped>
.ultra-content {
  overflow: visible;
}
</style>