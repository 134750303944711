<template>
  <section class="block-movie gb-section" v-if="movie">
    <img :src="movie.image" alt="slide-top" v-if="movie.image" data-aos="slide-down" />
    <div class="block-movie-wrapper gb-wrapper">
      <div class="block-movie-button" data-aos="zoom-in" v-if="movie.movieAction">
        <UltraLink :action="movie.movieAction">
          <i class="icon-play-fill ultra-large"></i>
        </UltraLink>
      </div>
      <div class="block-movie-card" data-aos="zoom-in-left">
        <h2 class="mb-20 fn-color secondary" v-if="movie.title">{{ movie.title }}</h2>
        <p class="mb-20" v-if="movie.description">{{ movie.description }}</p>
        <p v-if="movie.action" class="fn-color secondary">
          <UltraAction :action="movie.action" />
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import UltraLink from "@/components/UltraLink";
import UltraAction from "@/components/UltraAction";
export default {
  name: "BlockMovie",
  components: {UltraAction, UltraLink},
  computed: {
    movie() {
      return this.$store.getters['movie'];
    }
  },
}
</script>

<style scoped>
.block-movie {
  overflow: hidden;
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  color: var(--color-white);
}
.block-movie:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #626262;
  mix-blend-mode: multiply;
}
.block-movie > img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: inline-block;
}
.block-movie-wrapper {
  height: 100%;
  min-height: 50vw;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 1;
}
.block-movie-card {
  width: calc(50% - 100px);
  text-align: left;
}
.block-movie-button {
  width: 200px;
  height: 200px;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  color: var(--color-white);
  cursor: pointer;
  transition: all ease-in-out .5s;
  margin-left: calc(50% - 100px);
}
.block-movie-button:before {
  content: '';
  display: inline-block;
  width: 164px;
  height: 164px;
  position: absolute;
  left: 18px;
  top: 18px;
  border-radius: 50%;
  background-color: var(--color-secondary);
  opacity: .6;
  transition: all ease-in-out .3s;
  pointer-events: none;
}
.block-movie-button:hover:before {
  opacity: .8;
  transform: scale(1.2);
  transition: all ease-in-out .3s;
}
@media only screen and (max-width: 600px) {
  .block-movie-wrapper {
    flex-direction: column;
    height: 80vh;
  }
  .block-movie-card {
    width: 100%;
    text-align: center;
  }
  .block-movie-button {
    margin-left: unset;
  }
}
</style>