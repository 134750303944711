<template>
  <li class="item-link" v-if="action"
      :class="[theme, { selected: action.selected ?? false }, { inactive: action.inactive ?? false }]"
  >
    <UltraLink :action="action" :withText="withText" />
  </li>
</template>

<script>
import UltraLink from "@/components/UltraLink";
export default {
  name: "UltraItemLink",
  components: {UltraLink},
  props: {
    action: {
      type: Object,
      required: false,
    },
    theme: {
      type: String,
      default: '',
    },
    withText: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.item-link {
  padding: 5px;
  text-transform: uppercase;
  color: currentColor;
  transition: color ease-in-out .3s;
  cursor: pointer;
  width: auto;
  overflow: hidden;
  font-weight: inherit;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
}
.item-link.inactive {
  cursor: default;
}
.item-link:hover {
  filter: brightness(.8);
  transition: all ease-in-out .3s;
}
.item-link.sublink {
  padding: 0 5px 0 20px;
  font-weight: 300;
  font-size: .9rem;
}
.item-link.horizontal {
  flex-direction: row;
}
</style>