<template>
  <div class="ultra-card-comment" v-if="comment">
    <header class="ultra-card-comment-header" data-aos="zoom-in-down">
      <p v-if="comment.name">{{ comment.name }}<span v-if="comment.age">, {{ comment.age }}</span></p>
    </header>
    <section class="ultra-card-comment-section" data-aos="zoom-in-up">
      <p v-if="comment.message">{{ comment.message }}</p>
    </section>
  </div>
</template>

<script>
export default {
  name: "UltraCardComment",
  props: {
    comment: {
      type: Object,
      required: true,
    },
  }
}
</script>

<style scoped>
.ultra-card-comment {
  width: 100%;
  height: auto;
  text-align: center;
}
.ultra-card-comment-header {
  margin-bottom: 20px;
  position: relative;
}
.ultra-card-comment-header p {
  font-weight: 600;
}
.ultra-card-comment-header span {
  font-weight: 300;
}
.ultra-card-comment-header:before {
  width: 100px;
  height: 2px;
  background-color: var(--color-secondary);
  position: absolute;
  bottom: -10px;
  content: '';
  margin-left: -50px;
}
.ultra-card-comment-section {
  padding: 0 20px;
}
</style>