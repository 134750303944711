<template>
  <div class="ultra-carousel" v-if="slides">
    <Carousel :settings="settings" :breakpoints="breakpoints">
      <Slide v-for="item in slides" :key="item.id">
        <div class="hs-10">
          <UltraImage :image="item" />
        </div>
      </Slide>
      <template #addons>
        <Pagination />
        <Navigation />
      </template>
    </Carousel>
  </div>
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import UltraImage from "@/components/UltraImage";

export default {
  name: "UltraCarousel",
  props: {
    slides: {
      type: Array,
      required: false,
    },
    itemsToShow: {
      type: Number,
      default: 1,
    },
    autoplay: {
      type: Number,
      default: 7000,
    },
  },
  computed: {
    settings() {
      const { itemsToShow } = this;
      return {
        itemsToShow: (itemsToShow > 1) ? itemsToShow / 2 : 1,
        snapAlign: 'center',
      };
    },
    breakpoints() {
      const { itemsToShow } = this;
      return {
        600: {
          itemsToShow,
          snapAlign: 'center',
        },
      };
    },
  },
  components: {
    UltraImage,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
}
</script>

<style scoped>
.ultra-carousel {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .ultra-carousel {
     padding: 0 10px;
  }
}
</style>