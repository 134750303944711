<template>
  <div class="form-download">
    <div class="form-line">
      <label for="name">Nome</label>
      <input id="name" name="name" type="text" v-model="name"
             @keyup="checkWithErrorForm"
             :class="{ error: !!errors.name }" placeholder="Seu nome completo" required />
      <div class="ultra-form-message error" v-if="errors.name" data-aos="zoom-in-up">{{ errors.name }}</div>
    </div>
    <div class="form-line">
      <label for="phone">Telefone</label>
      <input id="phone" name="phone" v-mask="['(##) ####-####', '(##) #####-####']"
             type="text" v-model="phone" :class="{ error: !!errors.name }"
             @update="checkWithErrorForm"
             placeholder="Seu telefone/whatsapp" required />
      <div class="ultra-form-message error" v-if="errors.phone" data-aos="zoom-in-up">{{ errors.phone }}</div>
    </div>
    <div class="form-line">
      <label for="email">E-mail</label>
      <input id="email" name="email" type="email" v-model="email"
             @keyup="checkWithErrorForm"
             :class="{ error: !!errors.name }" placeholder="Seu melhor e-mail" required />
      <div class="ultra-form-message error" v-if="errors.email" data-aos="zoom-in-up">{{ errors.email }}</div>
    </div>
    <div class="form-line">
      <label for="message">Mensagem</label>
      <textarea id="message" name="message" v-model="message"
             @keyup="checkWithErrorForm"
             :class="{ error: !!errors.name }" placeholder="Sua mensagem" required>
      </textarea>
      <div class="ultra-form-message error" v-if="errors.message" data-aos="zoom-in-up">{{ errors.message }}</div>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';

export default {
  name: "FormContact",
  directives: { mask },
  data() {
    return {
      name: '',
      phone: '',
      email: '',
      message: '',
      errors: {},
    };
  },
  methods: {
    checkWithErrorForm() {
      if (Object.keys(this.errors).length)
        this.checkForm();
    },
    checkForm() {
      const { name, phone, email, message } = this;
      this.errors = {};

      if (!name) {
        this.errors.name = 'O campo nome não pode estar vaziu.';
      } else if (name.length < 3) {
        this.errors.name = 'O campo nome esta muito curto.';
      }

      if (!phone) {
        this.errors.phone = 'O campo telefone não pode estar vaziu.';
      } else if (phone.length < 14) {
        this.errors.phone = 'Preencha corretamente o campo telefone.';
      }

      if (!email) {
        this.errors.email = 'O campo e-mail não pode estar vaziu.';
      } else if (!this.validEmail(email)) {
        this.errors.email = 'Preencha corretamente o campo e-mail.';
      }

      if (!message) {
        this.errors.message = 'O campo mensagem não pode estar vaziu.';
      } else if (message.length < 10) {
        this.errors.message = 'O campo mensagem esta muito curto.';
      }

      return !Object.keys(this.errors).length;
    },
    get() {
      const { name, phone, email, message } = this;
      return { name, phone, email, message };
    },
    reset() {
      this.name = '';
      this.phone = '';
      this.email = '';
      this.message = '';
    },
    validEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
}
</script>

<style scoped>
.form-download {
  display: inline-flex;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: auto;
  flex-direction: column;
  margin-bottom: 5px;
}
.form-line {
  display: inline-flex;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: auto;
  flex-direction: column;
  margin-bottom: 10px;
}
.form-line label {
  display: none;
}
.form-line textarea,
.form-line input {
  border: 1px solid var(--color-primary-light);
  background: var(--color-white);
  height: 40px;
  border-radius: 10px;
  padding: 0 10px;
  outline: none;
}
.form-line textarea.error,
.form-line input.error {
  border: 1px solid darkred;
}
.form-line textarea {
  height: 100px;
  padding: 5px 10px;
}
.ultra-form-message {
  width: 100%;
  padding: 5px 10px 4px 10px;
  border-radius: 5px;
  background-color: var(--color-black);
  margin-bottom: 5px;
  font-size: .8rem;
  line-height: .8rem;
  font-weight: 500;
}
.ultra-form-message.success {
  background-color: var(--color-primary-light);
}
.ultra-form-message.error {
  background-color: darkred;
}
</style>