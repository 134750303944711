<template>
  <div class="block-items gb-section" v-if="items">
    <div class="block-items-wrapper gb-wrapper" v-if="items.data">
      <UltraColText columns="two"
                    class="mb-20"
                    v-for="item in items.data" :key="item.id">
        <div class="block-items-item">
          <UltraCardPost :post="item" v-if="items.template && items.template === 'blog'" />
          <UltraImage :image="item" v-else />
        </div>
      </UltraColText>
    </div>
    <div class="block-items-wrapper gb-wrapper">
      <UltraPagination :pagination="items" class="mb-20" />
    </div>
  </div>
</template>

<script>
import UltraColText from "@/components/UltraColText";
import UltraImage from "@/components/UltraImage";
import UltraPagination from "@/components/UltraPagination";
import UltraCardPost from "@/components/UltraCardPost";
export default {
  name: "BlockItems",
  components: {UltraCardPost, UltraPagination, UltraImage, UltraColText},
  computed: {
    items() {
      return this.$store.getters['items'];
    },
  },
}
</script>

<style scoped>
.block-items-wrapper {
  flex-direction: row;
  flex-wrap: wrap;
  padding: 60px 0 40px 0;
}
</style>