<template>
  <div class="ultra-pagination" v-if="pagination && pages">
    <ul class="ultra-pagination-list">
      <li class="ultra-pagination-items prev" v-if="pagination.prev_page_url">
        <a class="ultra-pagination-items-link" :href="pagination.prev_page_url" target="_self">&lt;</a>
      </li>
      <li class="ultra-pagination-items last" v-for="page in pages" :class="{ current: page.current }" :key="page.page">
        <a class="ultra-pagination-items-link" :href="page.url" target="_self">{{ page.page }}</a>
      </li>
      <li class="ultra-pagination-items next" v-if="pagination.next_page_url">
        <a class="ultra-pagination-items-link" :href="pagination.next_page_url" target="_self">&gt;</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "UltraPagination",
  props: {
    pagination: {
      type: Object,
      required: false,
    },
  },
  computed: {
    pages() {
      const total_show = 5;
      const { path, current_page, last_page } = this.pagination;
      if (!path || !current_page || !last_page) return null;

      const first_page_show = current_page === 1 ? 1 : (current_page - total_show) >= 1 ? (current_page - total_show) : 1;
      const last_page_show = (first_page_show + total_show) < last_page ? first_page_show + total_show : last_page;

      let result = [];
      for (let i = first_page_show; i <= last_page_show; i++) {
        result.push({
          url: `${path}?page=${i}`,
          page: i,
          current: i === current_page,
        });
      }
      return result;
    },
  },
}
</script>

<style scoped>
.ultra-pagination {
  width: 100%;
  text-align: center;
}
.ultra-pagination-list {
  display: inline-block;
  padding: 0;
  margin: 0;
  list-style: none;
  min-height: 60px;
}
.ultra-pagination-items {
  display: inline-flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin: 10px;
  font-weight: 600;
  background-color: #d8d8d8;
  color: var(--color-black);
  border: 2px solid transparent;
  cursor: pointer;
  transition: all .3s ease-in-out;
  overflow: hidden;
  position: relative;
}
.ultra-pagination-items:hover {
  border: 2px solid rgba(0, 0, 0, .2);
  transition: all .3s ease-in-out;
}
.ultra-pagination-items.current {
  color: var(--color-white);
  font-weight: 700;
  background-color: var(--color-black);
  border: 2px solid rgba(0, 0, 0, .2);
  pointer-events: none;
  transition: all .3s ease-in-out;
}
a.ultra-pagination-items-link:hover,
a.ultra-pagination-items-link:visited,
a.ultra-pagination-items-link:link,
a.ultra-pagination-items-link {
  display: inline-flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
</style>