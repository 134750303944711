<template>
  <UltraProfessionals :professionals="professionals" />
</template>

<script>
import UltraProfessionals from "@/components/UltraProfessionals";
export default {
  name: "BlockProfessionals",
  components: {UltraProfessionals},
  computed: {
    professionals() {
      return this.$store.getters['professionals'];
    },
  },
}
</script>

<style scoped>

</style>