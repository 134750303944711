<template>
  <li class="item-link submenu" v-if="item"
      :class="[theme, { selected: item.action.selected ?? false }, { inactive: item.action.inactive ?? false }, position]"
  >
    <UltraLink :action="item.action" :withText="withText" v-if="!item.submenu || !item.submenu.length" />
    <div class="item-link-selectable" v-else @click="openMenu">
      <UltraLink :action="item.action" :withText="withText" />
      <i class="icon-chevron-down small ml-5"></i>
      <div class="item-link-submenu" v-if="menu" data-aos="zoom-in-down">
        <UltraLink :action="submenu.action" v-for="submenu in item.submenu" :key="submenu.id" :withText="withText" />
      </div>
    </div>
  </li>
</template>

<script>
import UltraLink from "@/components/UltraLink";
export default {
  name: "UltraItemLinkWithSubMenu",
  data() {
    return {
      menu: false,
    };
  },
  components: {UltraLink},
  props: {
    item: {
      type: Object,
      required: false,
    },
    theme: {
      type: String,
      default: '',
    },
    position: {
      type: String,
      required: false,
    },
    withText: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openMenu() {
      this.menu = !this.menu;
    },
  },
}
</script>

<style scoped>
.item-link {
  padding: 5px;
  text-transform: uppercase;
  color: currentColor;
  transition: color ease-in-out .3s;
  cursor: pointer;
  width: auto;
  overflow: hidden;
  font-weight: inherit;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.item-link.submenu {
  overflow: visible;
}
.item-link-selectable {
  cursor: pointer;
  width: auto;
  font-weight: inherit;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.item-link-selectable > a {
  pointer-events: none;
}
.item-link.inactive {
  cursor: default;
}
.item-link:hover {
  filter: light(150);
  transition: all ease-in-out .3s;
}
.item-link.sublink {
  padding: 0 5px 0 20px;
  font-weight: 300;
  font-size: .9rem;
}
.item-link-submenu:before {
  content: '';
  position: absolute;
  top: -5px;
  right: 12px;
  background-color: var(--color-primary-dark);
  width: 12px;
  height: 12px;
  rotate: 45deg;
}
.item-link-submenu {
  position: absolute;
  top: 0;
  right: -10px;
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 2rem;
  background-color: var(--color-primary-dark);
  width: auto;
  height: auto;
  padding: 0;
  border-radius: 4px;
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
  pointer-events: all;
  color: white;
  box-shadow: 0 2px 2px rgba(0, 0, 0, .4);
}
.item-link-submenu a {
  width: 100%;
  padding: 10px 20px;
  border-bottom: 1px solid var(--color-primary);
  transition: all ease-in-out .3s;
  justify-content: flex-end;
}
.item-link-submenu a:hover {
  filter: brightness(.8);
  transition: all ease-in-out .3s;
}
.item-link-submenu a:last-child {
  border-bottom: unset;
}
.top .item-link-submenu {
  top: unset;
  bottom: 0;
  margin-top: unset;
  margin-bottom: 2rem;
}
.top .item-link-submenu:before {
  top: unset;
  bottom: -5px;
}
</style>