<template>
  <div class="ultra-modal" data-aos="slide-up" v-if="modal && modal.show" :class="{ landscape: modal.orientation === 'landscape' }">
    <div class="ultra-modal-wrapper" data-aos="zoom-in-up" data-aos-delay="300">
      <div class="ultra-modal-close" @click.prevent="hideModal">
        <i class="icon-x"></i>
      </div>
      <div class="ultra-modal-body" v-if="modal.url" data-aos="zoom-in-up" data-aos-delay="600">
        <iframe :src="modal.url" frameborder="0" marginheight="0" marginwidth="0">Carregando…</iframe>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from "@/mixins";

export default {
  name: "UltraModal",
  mixins: [mixins],
  computed: {
    modal() {
      return this.$store.getters['modal'];
    },
  },
}
</script>

<style scoped>
.ultra-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .6);
  backdrop-filter: blur(20px);
  z-index: 2000;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.ultra-modal-wrapper {
  width: 640px;
  height: calc(100vh - 100px);
  position: relative;
  background-color: var(--color-primary-dark);
  border-radius: 20px;
  /*box-shadow: 0 2px 4px rgba(0, 0, 0, .4);*/
  display: inline-flex;
  justify-content: stretch;
  align-items: flex-start;
  color: var(--color-white);
  padding: 20px;
}
.landscape > .ultra-modal-wrapper {
  width: calc(100vw - 140px);
  height: calc(100vh - 100px);
}
.ultra-modal-close {
  position: absolute;
  right: -70px;
  top: 0;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: background-color ease-in-out .3s;
  cursor: pointer;
}
.ultra-modal-close:hover {
  background-color: var(--color-primary-dark);
  transition: background-color ease-in-out .3s;
}
.ultra-modal-body {
  background-color: var(--color-white);
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.ultra-modal-body iframe {
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 600px) {
  .ultra-modal-wrapper {
    width: 100%;
    height: calc(100vh - 180px);
    position: relative;
    background-color: var(--color-primary-dark);
    border-radius: 20px;
    /*box-shadow: 0 2px 4px rgba(0, 0, 0, .4);*/
    display: inline-flex;
    justify-content: stretch;
    align-items: flex-start;
    color: var(--color-white);
    padding: 20px;
  }
  .landscape > .ultra-modal-wrapper {
    width: 100%;
    height: 80vw;
  }
  .ultra-modal-close {
    position: absolute;
    right: 0;
    top: -70px;
  }
}
</style>