<template>
  <div class="ultra-highlight" v-if="highlight && !Array.isArray(highlight)">
    <UltraBox :theme="highlight.details ? highlight.details.theme : 'default'">
      <UltraColText columns="one" class="ultra-highlight-header" data-aos="zoom-in-right">
        <h2 class="ultra-highlight-header-title fn-color"
            :class="highlight.details.titleColor ?? 'primary'"
            v-if="highlight.title">{{ highlight.title }}</h2>
        <p class="ultra-highlight-header-description fn-color" v-if="highlight.description"
           :class="highlight.details.textColor ?? 'black'">{{ highlight.description }}</p>
        <p class="ultra-highlight-header-action fn-color" :class="highlight.details.actionColor ?? 'white'">
          <UltraAction v-if="highlight.action" :action="highlight.action" />
        </p>
      </UltraColText>
      <UltraColText columns="one" v-if="highlight.image" class="mt-20">
        <UltraImage :image="highlight.image" v-if="!Array.isArray(highlight.image)" />
        <UltraCarousel v-else :slides="highlight.image" :itemsToShow="2" class="fn-color"
                       :class="highlight.details.textColor ?? 'secondary'" />
      </UltraColText>
    </UltraBox>
  </div>
  <div class="ultra-highlight" v-else-if="highlight && Array.isArray(highlight)">
    <UltraBox v-for="(item, index) in highlight"
              :theme="item.details ? item.details.theme : 'default'"
              :key="item.id ?? index">
      <UltraColText columns="one" class="ultra-highlight-header" data-aos="zoom-in-right">
        <h2 class="ultra-highlight-header-title fn-color"
            :class="item.details.titleColor ?? 'primary'"
            v-if="item.title">{{ item.title }}</h2>
        <p class="ultra-highlight-header-description fn-color" v-if="item.description"
           :class="item.details.textColor ?? 'black'">{{ item.description }}</p>
        <p class="ultra-highlight-header-action fn-color" :class="item.details.actionColor ?? 'white'">
          <UltraAction v-if="item.action" :action="item.action" />
        </p>
      </UltraColText>
      <UltraColText columns="one" v-if="item.image" class="mt-20">
        <UltraImage :image="item.image" v-if="!Array.isArray(item.image)" />
        <UltraCarousel v-else :slides="item.image" :itemsToShow="2" class="fn-color"
                       :class="item.details.textColor ?? 'secondary'" />
      </UltraColText>
    </UltraBox>
  </div>
</template>

<script>
import UltraBox from "@/components/UltraBox";
import UltraColText from "@/components/UltraColText";
import UltraAction from "@/components/UltraAction";
import UltraImage from "@/components/UltraImage";
import UltraCarousel from "@/components/UltraCarousel";
export default {
  name: "UltraHighlightPages",
  props: {
    highlight: {
      type: [Object, Array],
      required: false,
    },
  },
  components: {
    UltraCarousel,
    UltraImage,
    UltraAction,
    UltraColText,
    UltraBox,
  },
}
</script>

<style scoped>
.ultra-highlight {
  width: 100%;
  height: auto;
  position: relative;
  display: inline-block;
}
.ultra-highlight-header {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.ultra-highlight-header-description {
  position: absolute;
  top: -20px;
}
</style>