<template>
  <UltraHighlight :highlight="highlight" />
</template>

<script>
import UltraHighlight from "@/components/UltraHighlight";

export default {
  name: "BlockHighlight",
  components: {UltraHighlight},
  computed: {
    highlight() {
      return this.$store.getters['highlight'];
    },
  },
}
</script>

<style scoped>

</style>