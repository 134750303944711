<template>
  <UltraBox v-if="drops" theme="default">
    <UltraColText columns="three" v-for="item in drops" :key="item.id">
      <UltraCard :card="item" />
    </UltraColText>
  </UltraBox>
</template>

<script>
import UltraColText from "@/components/UltraColText";
import UltraBox from "@/components/UltraBox";
import UltraCard from "@/components/UltraCard";
export default {
  name: "BlockDrops",
  components: {UltraCard, UltraBox, UltraColText},
  computed: {
    drops() {
      return this.$store.getters['drops'];
    },
  },
}
</script>

<style scoped>
.block-drops {
  background: var(--color-white);
}
.block-drops {
  text-align: center;
  padding: 80px 0;
}
</style>